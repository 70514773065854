import React, { useRef } from 'react';
import Button from '../../components/Button';
import Input, { InputRefs } from '../../components/Input';

import {
  Container,
  KeyboardView,
  Content,
  Text,
  Form,
  Row,
  StepBack,
  StepBackIcon,
  StepBackText,
} from './styles';

import Phases from '../../components/Phases';
import { Icons } from '../../assets/Icons';
import { NumericKeyboard } from '../../components/NumericKeyboard';

import { celularMask } from 'masks-br';
import { PaymentBilletType } from '../../contexts/PaymentContext';

type Props = {
  next: () => void;
  previous: () => void;
  setData: (data: PaymentBilletType) => void;
  data: PaymentBilletType;
};

const Phone: React.FC<Props> = ({ next, previous, setData, data }) => {
  const inputRef = useRef<InputRefs>(null);

  function _previous() {
    setData({
      ...data,
      name: '',
      phone: '',
    });
    previous();
  }

  return (
    <Container>
      <Content>
        <Phases
          icons={[Icons.lock, Icons.pencil, Icons.lock, Icons.whiteCheck]}
          texts={['Identificação', 'Dados do boleto', 'Pagamento', '']}
          step={1}
        />
        <Form>
          <Text>
            Como falamos
            <br />
            com você?
          </Text>
          <Row>
            <Input
              ref={inputRef}
              placeholder="Celular com DDD"
              name="phone_number"
              type="phone"
              value={data.phone}
              onChange={(phone) =>
                setData({
                  ...data,
                  phone: celularMask(phone),
                })
              }
              required
            />
            <Button
              text="Continuar"
              variant="primary"
              onClick={next}
              disabled={data.phone.length < 15}
            />
          </Row>
          <StepBack onClick={_previous}>
            <StepBackIcon src={Icons.chevron_left} />
            <StepBackText>Voltar</StepBackText>
          </StepBack>
        </Form>
        <KeyboardView>
          <NumericKeyboard
            maxLength={11}
            onChange={(e) =>
              inputRef.current && inputRef.current.setValues('phone', e)
            }
          />
        </KeyboardView>
      </Content>
    </Container>
  );
};

export default Phone;
