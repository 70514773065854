import styled from 'styled-components';
import px2vw from '../../../utils/pc2vw';
import pc2vw from '../../../utils/pc2vw';

export const QuestionTitle = styled.h1`
  font-weight: 200;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_one)};
  text-align: center;
  width: ${pc2vw(330)};
`;

export const ContainerSms = styled.div`
  width: ${px2vw(768)};
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${px2vw(40)};
  padding-top: 20vh;
`;
