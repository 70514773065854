import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

type Props = {
  authenticated: boolean;
};

const PublicRoute: React.FC<Props> = ({ authenticated }) => {
  if (authenticated) {
    return <Navigate to="/inicial" replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
