import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${px2vw(30)};
  gap: ${px2vw(30)};

  width: ${px2vw(568)};
  height: ${px2vw(174)};

  background: ${({ theme }) => theme.colors.white_full};
  border: ${px2vw(3)} solid rgba(0, 0, 0, 0.12);
  box-shadow: ${({ theme }) => theme.box_shadow.level_two};
  border-radius: ${px2vw(30)};
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.12));
`;

export const Icon = styled.img`
  width: ${px2vw(64)};
  height: ${px2vw(64)};
`;

export const PrimaryText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_two)};
  line-height: ${px2vw(36)};

  text-align: left;
`;

export const SecondaryText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_tree)};
  line-height: ${px2vw(27)};
  text-align: left;
  color: ${({ theme }) => theme.colors.dark_mid};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(24)};
`;
