import React, { useRef, useState } from 'react';

import 'react-simple-keyboard/build/css/index.css';
import Button from '../../components/Button';
import Input, { InputRefs } from '../../components/Input';
import { NumericKeyboard } from '../../components/NumericKeyboard';
import Phases from '../../components/Phases';

import {
  Container,
  Content,
  Form,
  Text,
  Row,
  StepBack,
  StepBackIcon,
  StepBackText,
} from './styles';
import { Icons } from '../../assets/Icons';
import { ScreenTypes } from '.';
import ActionButton from '../../components/ActionButton';

type Props = {
  goToScreen: (name: ScreenTypes) => void;
  fetchBillet: (billet_code: string) => void;
  goBack: () => void;
};

const DigitableLine: React.FC<Props> = ({
  goToScreen,
  fetchBillet,
  goBack,
}) => {
  const inputRef = useRef<InputRefs>(null);
  const [digitable, setDigitable] = useState('');

  return (
    <Container>
      <Content>
        <Phases
          icons={[Icons.lock, Icons.pencil, Icons.lock, Icons.whiteCheck]}
          texts={['Identificação', 'Dados do boleto', 'Pagamento', '']}
          step={2}
        />
        <Form>
          <Text>
            Digite a linha
            <br />
            digitável:
          </Text>
          <Row>
            <Input
              ref={inputRef}
              autoFocus
              placeholder="Linha digitável"
              name="digitable_line"
              type="digitable_line"
              onChange={(a) => setDigitable(a)}
              // value={digitable}
              rtl
              required
            />
            <Button
              text="Continuar"
              variant="primary"
              onClick={() => fetchBillet(digitable)}
            />
            <ActionButton
              color="primary"
              text="Ler o código de barras"
              icon="capture"
              onClick={() => goToScreen(ScreenTypes.Leitor)}
            />
          </Row>
          <StepBack onClick={goBack}>
            <StepBackIcon src={Icons.chevron_left} />
            <StepBackText>Voltar</StepBackText>
          </StepBack>
        </Form>
        <NumericKeyboard
          maxLength={48}
          onChange={(e) =>
            inputRef.current && inputRef.current.setValues('digitable_line', e)
          }
        />
      </Content>
    </Container>
  );
};

export default DigitableLine;
