import feather from './images/svg/feather.svg';
import chevron_left from './images/svg/chevron_left.svg';
import chevron_down from './images/svg/chevron_down.svg';
import barcode_scan from './images/svg/barcode_scan.svg';
import note from './images/svg/note.svg';
import logo from './images/svg/logo.svg';
import lock from './images/svg/lock.svg';
import lock_white from './images/svg/lock_white.svg';
import pencil from './images/svg/pencil.svg';
import whiteCheck from './images/svg/white-check.svg';
import barcode from './images/svg/barcode.svg';
import trash from './images/svg/trash.svg';
import totem_sensibility from './images/svg/totem-sensibility.svg';
import keyboard_icon from './images/svg/keyboard-show.svg';
import capture from './images/svg/capture.svg';
import arrow_print from './images/svg/arrow-printer.svg';
import chevron_right_outline from './images/svg/chevron_right_outline.svg';

export const Icons = {
  feather,
  chevron_left,
  chevron_down,
  barcode_scan,
  note,
  lock,
  lock_white,
  pencil,
  whiteCheck,
  barcode,
  trash,
  totem_sensibility,
  keyboard_icon,
  capture,
  chevron_right_outline,
};

export const Images = {
  logo,
  arrow_print,
};
