import React, { useEffect, useRef, useState } from 'react';

import 'react-simple-keyboard/build/css/index.css';
import Phases from '../../components/Phases';

import {
  Container,
  Content,
  StepBack,
  TextHead,
  ButtonPayment,
  StepBackText,
  StepBackIcon,
  MessageContainerBankslip,
  FragmentDiv,
} from './styles';
import { CheckedItem } from '../../components/CheckedItem';
import { ButtonWithLength } from '../../components/ButtonWithLength';
import { Icons } from '../../assets/Icons';
import ActionButton from '../../components/ActionButton';
import { ScreenTypes } from '.';
import UniqueOptions from '../Payment/Unique/Options';
import UniqueOptionsCredit from '../Payment/Unique/OptionsCredit';
import usePayment, {
  PaymentBilletType,
  PaymentOptionsRefs,
} from '../../contexts/PaymentContext';
import useUtil from '../../contexts/UtilContext';

type Props = {
  goToScreen: (name: ScreenTypes) => void;
  removeBillet: (index: number) => void;
  goBack: () => void;
};

const Debits: React.FC<Props> = ({ goToScreen, removeBillet, goBack }) => {
  const [sum, setSum] = useState(0);
  const { dataPaymentBillet } = usePayment();
  const { showMessage } = useUtil();

  const unicPaymentRef = useRef<PaymentOptionsRefs>(null);
  const unicOptionsCredRef = useRef<PaymentOptionsRefs>(null);

  const [listCheck, setListCheck] = useState<number[]>([]);
  const [indexLicense, setIndexLicense] = useState<number>(0);

  useEffect(() => {
    const debits = dataPaymentBillet.debits;
    if (!debits || !debits.length) {
      return;
    }
    let sum = 0;
    listCheck.forEach((index) => {
      sum = sum + debits[index].valor;
    });
    setSum(sum);
  }, [listCheck]);

  useEffect(() => {
      const debits = dataPaymentBillet.debits;

      setListCheck(Array.from(Array(debits.length).keys()))

      setIndexLicense(debits.findIndex((item) => item.is_license));
  }, []);

  function openUnicPayment() {
    unicPaymentRef.current && unicPaymentRef.current.handleOpen();
  }

  function goBackOptionsCredit() {
    unicOptionsCredRef.current && unicOptionsCredRef.current.handleOpen();
    unicPaymentRef.current && unicPaymentRef.current.handleOpen();
  }

  function handleCheck(index: number) {
    if (listCheck.indexOf(index) > -1) {
      const arr_without_item = listCheck.filter((item) => item !== index);
      setListCheck(removeLicenseInListIndex(arr_without_item));
      return;
    }

    setListCheck((oldValue) => [...oldValue, index]);
  }

  function removeLicenseInListIndex(arr_without_item: number[]) {
    return arr_without_item.filter((item) => item !== indexLicense);
  }

  function shortArray(debits: typeof dataPaymentBillet.debits) {
    const array_sorted = debits.sort(
      (a, b) => Number(b.is_license ? 1 : 0) - Number(a.is_license ? 1 : 0)
    );

    return array_sorted;
  }

  return (
    <Container>
      <Content>
        <Phases
          icons={[
            Icons.lock_white,
            Icons.pencil,
            Icons.lock_white,
            Icons.whiteCheck,
          ]}
          texts={['Identificação', 'Dados do pagamento', 'Pagamento', '']}
          step={2}
        />
        <MessageContainerBankslip>
          <TextHead>Selecione o que quer pagar</TextHead>
          {shortArray(dataPaymentBillet.debits).map((billet, index) => (
            <CheckedItem
              checked={listCheck.includes(index)}
              onChangeChecked={() => handleCheck(index)}
              key={index}
              billet={billet}
              removeBillet={() => removeBillet(index)}
            />
          ))}

          <ActionButton
            color="primary"
            text="Adicionar Débitos"
            icon="feather"
            onClick={() => goToScreen(ScreenTypes.FetchDebits)}
          />
          <StepBack onClick={goBack}>
            <StepBackIcon src={Icons.chevron_left} />
            <StepBackText>Voltar</StepBackText>
          </StepBack>
        </MessageContainerBankslip>
        <FragmentDiv />
      </Content>
      {listCheck.length && (
        <ButtonPayment>
          <ButtonWithLength
            onClick={openUnicPayment}
            text={`Total de (${listCheck.length}) boletos`}
            amount={sum}
          />
        </ButtonPayment>
      )}
      <UniqueOptions
        value={sum}
        ref={unicPaymentRef}
        moreOptionsCredit={unicOptionsCredRef}
      />
      <UniqueOptionsCredit
        value={sum}
        ref={unicOptionsCredRef}
        goBack={goBackOptionsCredit}
      />
    </Container>
  );
};

export default Debits;
