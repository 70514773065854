import React, { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { BsDot } from 'react-icons/bs';
import Button from '../../../components/Button';
import {
  Container,
  ContainerButton,
  ContainerButtonInside,
  ContainerGridButtons,
  ContainerInsideButton,
  MainTitle,
  StepBack,
  StepBackIcon,
  StepBackText,
  SubTextButton,
  TextButton,
  TextButtonBold,
} from './styles';
import { cleanMask, dinheiroMask } from 'masks-br';
import { useAuth } from '../../../contexts/AuthContext';
import CondicaoPagamento from '../../../assets/jsons/condicao_pagamento.json';
import paymentCalc, { PaymentCalc } from '../../../utils/paymentCalc';
import { useNavigate } from 'react-router-dom';
import { Icons } from '../../../assets/Icons';
import usePayment, {
  EnumPaymentForm,
  PaymentInstallments,
} from '../../../contexts/PaymentContext';
import { moneyMask } from '../../../utils/utils';
import useUtil from '../../../contexts/UtilContext';
import parcelamos from '../../../services/parcelamos';

interface UniqueOptionsCreditProps {
  children?: React.ReactNode;
  value: number;
  goBack: () => void;
}

export type PaymentOptionsCreditRefs = {
  handleOpen: () => void;
};

type Payment_installments_cal = PaymentInstallments & {
  payment_calc: PaymentCalc;
};
const UniqueOptionsCredit = forwardRef(
  (props: UniqueOptionsCreditProps, ref: Ref<PaymentOptionsCreditRefs>) => {
    const navigate = useNavigate();
    const { value, goBack } = props;
    const [open, setOpen] = useState<boolean | undefined>();
    const { user } = useAuth();
    const {
      dataPaymentBillet,
      setPaymentBilletData,
      processPaymentBilletData,
    } = usePayment();
    const { showMessage } = useUtil();

    useImperativeHandle(ref, () => ({
      handleOpen() {
        onShow();
      },
    }));

    function onShow() {
      setOpen(!open);
    }

    async function handleToPaymentProcessing(
      data: PaymentCalc,
      payment_form: EnumPaymentForm = EnumPaymentForm.CREDIT
    ) {
      const payment_identifier = await handleSaveDataPaymentBillet(data);

      if (
        !payment_identifier ||
        (payment_identifier && !payment_identifier.num_pedido) ||
        !payment_identifier.id_transacao_totem
      ) {
        showMessage({
          type: 'error',
          message: 'Erro ao reconhecer a transação',
        });
        return navigate('/inicial');
      }

      setPaymentBilletData({
        ...dataPaymentBillet,
        installments_number: data.installments_number,
        installments_value: moneyMask(data.installment_amount_interest),
        amount: moneyMask(data.payment_amount),
        payment_identifier: payment_identifier.id_transacao_totem,
        payment_cart_number: payment_identifier.num_pedido,
      });

      onShow();

      processPaymentBilletData(
        {
          ...dataPaymentBillet,
          installments_number: data.installments_number,
          installments_value: moneyMask(data.installment_amount_interest),
          amount: moneyMask(data.payment_amount),
          payment_identifier: payment_identifier.id_transacao_totem,
          payment_cart_number: payment_identifier.num_pedido,
        },
        payment_form
      );
      navigate('/inserir-cartao');
    }

    async function handleSaveDataPaymentBillet(data: PaymentCalc) {
      if (!user) {
        showMessage({
          type: 'error',
          message: 'Erro ao reconhecer o estabelecimento',
        });
        return navigate('/inicial');
      }

      const payment_data = await parcelamos.savePaymentData({
        ...dataPaymentBillet,
        phone: cleanMask(dataPaymentBillet.phone),
        document: cleanMask(dataPaymentBillet.document),
        installments_number: data.installments_number,
        installments_value: moneyMask(data.installment_amount_interest),
        amount: moneyMask(data.payment_amount),
        id_usuario: user.usuario.id_usuario,
        id_estabelecimento: user.usuario.id_estabelecimento,
      });
      return payment_data;
    }

    const getPaymentInstallments = (): Payment_installments_cal[] => {
      const condicao_pagamento = user
        ? user.condicao_pagamento
        : CondicaoPagamento.condicao_pagamento;

      const amounts_enable = condicao_pagamento.filter(
        (condicao) => condicao.id_forma_pagamento === EnumPaymentForm.CREDIT
      );
      return amounts_enable.map((amount_enable) => {
        const calc_amount_enable = paymentCalc({
          bankslip_value: value,
          installments_number: amount_enable.qtde_parcelas,
          payment_form: EnumPaymentForm.CREDIT,
        });
        return {
          installments_number: amount_enable.qtde_parcelas,
          installments_value: dinheiroMask(
            calc_amount_enable.installment_amount_interest
          ),
          amount: dinheiroMask(calc_amount_enable.payment_amount),
          payment_calc: calc_amount_enable,
        };
      });
    };

    return (
      <Container open={open}>
        <MainTitle>Selecione como quer pagar</MainTitle>
        <ContainerGridButtons>
          {getPaymentInstallments().map((payment_installments, index) => (
            <ContainerButton key={index}>
              <Button
                variant="secondary"
                disabled={
                  payment_installments.payment_calc
                    .installment_amount_interest < 5
                }
                style={ContainerButtonInside as React.CSSProperties}
                onClick={() =>
                  handleToPaymentProcessing(payment_installments.payment_calc)
                }
              >
                <ContainerInsideButton>
                  <TextButtonBold>Crédito</TextButtonBold>
                  <BsDot />
                  <TextButton>
                    {payment_installments.installments_number}x
                  </TextButton>
                  <BsDot />
                  <TextButton>
                    {payment_installments.installments_value}
                  </TextButton>
                </ContainerInsideButton>
                <ContainerInsideButton>
                  <SubTextButton>Valor Total</SubTextButton>
                  <BsDot />
                  <SubTextButton>{payment_installments.amount}</SubTextButton>
                </ContainerInsideButton>
              </Button>
            </ContainerButton>
          ))}
        </ContainerGridButtons>
        <StepBack onClick={goBack}>
          <StepBackIcon src={Icons.chevron_left} />
          <StepBackText>Voltar</StepBackText>
        </StepBack>
      </Container>
    );
  }
);

export default UniqueOptionsCredit;
