import { Callback } from 'yup/lib/types';
import {
  RequestCreditSale,
  RequestDebitSale,
  ResponseCreditSale,
} from './types';
import { format } from 'date-fns';

class Webtef {
  // private url = 'wss://192.168.3.8:8083/webtefapi';
  private url = 'wss://localhost:8083/webtefapi';
  public websocketapi: WebSocket;
  constructor() {
    this.websocketapi = new WebSocket(this.url);
  }

  getPinpadStatus(callback: Callback) {
    this.websocketapi.onopen = () => {
      console.log('Get Pinpad Status');
      this.websocketapi.send('{Comando:"1020"}');
      this.websocketapi.onmessage = ({ data }) => {
        if (data) {
          callback(null, JSON.parse(data));
          this.close();
        }
      };
    };
  }

  initialize(callback: Callback) {
    this.websocketapi.onopen = () => {
      console.log('Pinpad Initialize');
      this.websocketapi.send('{Comando:"6666"}');
    };
    this.websocketapi.onmessage = ({ data }) => {
      console.log(data);
      if (data) {
        callback(null, JSON.parse(data));
        this.close();
      }
    };
  }

  createCreditSale(
    data: Omit<
      RequestCreditSale,
      | 'Comando'
      | 'DataTx'
      | 'HoraTx'
      | 'Bandeiras'
      | 'TipoParcela'
      | 'ModoParcelada'
      | 'Trilha2'
      | 'Cvv'
    >,
    callback: Callback<ResponseCreditSale>
  ) {
    this.websocketapi.onopen = () => {
      console.log('Pinpad Create credit sale');
      const sale: RequestCreditSale = {
        Comando: '2000',
        DataTx: format(new Date(), 'MMdd'),
        HoraTx: format(new Date(), 'HHmmss'),
        Parcelas: Number(data.Parcelas) <= 1 ? '0' : data.Parcelas,
        TipoParcela: Number(data.Parcelas) <= 1 ? 0 : 2,
        Bandeiras: [],
        ModoParcelada: 2,
        Valor: data.Valor,
        Cvv: '000',
        Trilha2: '',
        Cpf: data.Cpf,
      };

      console.log(sale);
      this.websocketapi.send(JSON.stringify(sale));
    };
    this.websocketapi.onmessage = ({ data }) => {
      callback(null, JSON.parse(data));
      console.log(data);
    };
  }

  createDebitSale(
    data: Omit<RequestDebitSale, 'Comando' | 'DataTx' | 'HoraTx'>,
    callback: Callback<ResponseCreditSale>
  ) {
    this.websocketapi.onopen = () => {
      console.log('Pinpad Create credit sale');
      const sale: RequestDebitSale = {
        Comando: '2100',
        DataTx: format(new Date(), 'MMdd'),
        HoraTx: format(new Date(), 'HHmmss'),
        Valor: data.Valor,
        Cpf: data.Cpf,
      };

      console.log(sale);
      this.websocketapi.send(JSON.stringify(sale));
    };
    this.websocketapi.onmessage = ({ data }) => {
      callback(null, JSON.parse(data));
      console.log(data);
    };
  }

  close() {
    if (this.websocketapi.OPEN === 1) {
      this.websocketapi.close();
      console.log('Get Pinpad Status Close');
    }
  }

  opening(callback: Callback) {
    this.websocketapi.onopen = () => {
      console.log('Opnening Pinpad');
      this.websocketapi.send('{Comando:"1050"}');
      this.websocketapi.onmessage = ({ data }) => {
        console.log(data);
        if (data) {
          callback(null, JSON.parse(data));
          //  this.close();
        }
      };
    };
  }

  closing(callback: Callback) {
    this.websocketapi.onopen = () => {
      console.log('Closing Pinpad');
      this.websocketapi.send('{Comando:"1070", SenhaFechamento:"1234"}');
      this.websocketapi.onmessage = ({ data }) => {
        console.log(data);
        if (data) {
          callback(null, JSON.parse(data));
          //  this.close();
        }
      };
    };
  }

  loadTables(callback: Callback) {
    this.websocketapi.onopen = () => {
      console.log('Load Pinpad tables');
      this.websocketapi.send('{Comando:"9000"}');
    };
    this.websocketapi.onmessage = ({ data }) => {
      if (data) {
        callback(null, JSON.parse(data));
        //  this.close();
      }
    };
  }
}

export default Webtef;
