import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import Button from '../../components/Button';
import Input, { InputRefs } from '../../components/Input';

import {
  Container,
  KeyboardView,
  Content,
  Text,
  Form,
  Row,
  StepBack,
  StepBackIcon,
  StepBackText,
} from './styles';

import Phases from '../../components/Phases';
import { Icons } from '../../assets/Icons';
import { NumericKeyboard } from '../../components/NumericKeyboard';
import { useNavigate } from 'react-router-dom';

import { PaymentBilletType } from '../../contexts/PaymentContext';

import ParcelamosService from '../../services/parcelamos';
import useUtil from '../../contexts/UtilContext';

type Props = {
  previous: () => void;
  setData: (data: PaymentBilletType) => void;
  data: PaymentBilletType;
  came_from: 'Billing' | 'Agreements';
};

const Document: React.FC<Props> = ({ previous, setData, data, came_from }) => {
  const navigate = useNavigate();
  const inputRef = useRef<InputRefs>(null);
  const { showMessage, documentIsError } = useUtil();

  async function handleSaveUserData(data: PaymentBilletType) {
    try {
      const response = await ParcelamosService.saveUserData({
        document: data.document,
        name: data.name,
        phone: data.phone,
      });

      if (!response._id) {
        showMessage({
          type: 'error',
          message: 'Não foi possível salvar os dados do usuário',
        });
        return;
      }

      navigate(came_from === 'Billing' ? '/pagamento-boleto' : '/convenios');
    } catch (error) {
      showMessage({
        type: 'error',
        message: 'Erro ao salvar os dados do usuário',
      });
    }
  }

  function _previous() {
    setData({
      ...data,
      phone: '',
      document: '',
    });
    previous();
  }

  return (
    <Container>
      <Content>
        <Phases
          icons={[Icons.lock, Icons.pencil, Icons.lock, Icons.whiteCheck]}
          texts={['Identificação', 'Dados do boleto', 'Pagamento', '']}
          step={1}
        />
        <Form>
          <Text>
            {data && data.name}, qual
            <br />
            seu CPF?
          </Text>
          <Row>
            <Input
              ref={inputRef}
              placeholder="CPF com 11 dígitos"
              name="document_number"
              type="cpf"
              value={data.document}
              onChange={(document) => setData({ ...data, document })}
              required
            />
            <Button
              text="Continuar"
              variant="primary"
              onClick={() => handleSaveUserData(data)}
              disabled={data.document.length < 14 || documentIsError}
            />
          </Row>
          <StepBack onClick={_previous}>
            <StepBackIcon src={Icons.chevron_left} />
            <StepBackText>Voltar</StepBackText>
          </StepBack>
        </Form>
        <KeyboardView>
          <NumericKeyboard
            maxLength={11}
            onChange={(e) =>
              inputRef.current && inputRef.current.setValues('cpf', e)
            }
          />
        </KeyboardView>
      </Content>
    </Container>
  );
};

export default Document;
