import { format, getYear } from 'date-fns';
import { dinheiroMask } from 'masks-br';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePayment from '../../../contexts/PaymentContext';
import {
  ContainerReceiptGenerated,
  Divider,
  Table,
  Logo,
  TableTd,
  FootTotal,
  Footer,
  InfoHeader,
  TableTr,
  TableTh,
  Title,
  SubTitle,
  Informations,
  ContainerReceiptCarrierText,
  ContainerPayer,
  PayerInformations,
} from './styles';

export interface IElementReceipt {
  amount: number;
  description: string;
  total: number;
}

export interface IReceiptGenerated {
  request_number: number;
  date: Date;
  elements: IElementReceipt[];
}

const ReceiptGenerated: React.FC = () => {
  const { paymentBilletResponse, dataPaymentBillet } = usePayment();
  const navigate = useNavigate();

  const [dataPrint, setDataPrint] = useState<IReceiptGenerated>();

  const [count, setCount] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => setCount(count - 1), 1000);
    if (count === 0) {
      navigate('/inicial');
    }
    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    generatePrintProof();
    const time = setTimeout(() => {
      window.print();
    }, 3000);
    return () => clearTimeout(time);
  }, []);

  function parseDateHour({
    dayMonth,
    hour,
  }: {
    dayMonth?: number;
    hour?: number;
  }) {
    if (dayMonth && hour) {
      const date_split = dayMonth.toString().split('');
      const hour_split = hour.toString().split('');
      const formated = `${date_split[2]}${date_split[3]}/${date_split[0]}${
        date_split[1]
      }/${getYear(new Date())} ${hour_split[0]}${hour_split[1]}:${
        hour_split[2]
      }${hour_split[3]}:${hour_split[4]}${hour_split[5]}`;
      return formated;
    }
    return format(new Date(), 'dd/MM/yyyy HH:mm:ss');
  }

  function generatePrintProof() {
    const print: IReceiptGenerated = {
      date: new Date(),
      request_number: paymentBilletResponse
        ? Number.parseInt(paymentBilletResponse.NumAut)
        : 0,
      elements: dataPaymentBillet.billets.map((billet) => ({
        amount: 1,
        description: billet.linha_digitavel,
        total: billet.valor_boleto,
      })),
    };

    setDataPrint(print);
  }

  const sumElementInArray = (elements: number[]) =>
    dinheiroMask(
      elements.reduce((accumulator, element) => accumulator + element, 0)
    );

  const sumFeesInArray = (elements: number[]) =>
    dinheiroMask(
      (elements.reduce((accumulator, element) => accumulator + element, 0) -
        Number(dataPaymentBillet.amount)) *
        -1
    );

  return (
    <ContainerReceiptGenerated>
      <ContainerReceiptCarrierText>
        {paymentBilletResponse &&
          paymentBilletResponse.TicketPortador.split('\n').map((item) => (
            <p>{item}</p>
          ))}
      </ContainerReceiptCarrierText>
      <Logo />
      <InfoHeader>
        <Title>COMPROVANTE</Title>

        <SubTitle>PEDIDO: {dataPaymentBillet.payment_cart_number}</SubTitle>
        <SubTitle>
          {paymentBilletResponse &&
            parseDateHour({
              dayMonth: paymentBilletResponse.DataHost,
              hour: paymentBilletResponse.HoraHost,
            })}
        </SubTitle>
      </InfoHeader>

      <Divider />
      <ContainerPayer>
        <SubTitle>Dados do Pagador:</SubTitle>
        <PayerInformations>{dataPaymentBillet.name}</PayerInformations>
        <PayerInformations>{dataPaymentBillet.phone}</PayerInformations>
        <PayerInformations>{dataPaymentBillet.document}</PayerInformations>
      </ContainerPayer>
      <Divider />
      <Table>
        <TableTr>
          <TableTh>DESCRICAO</TableTh>
          <TableTh>TOTAL</TableTh>
        </TableTr>
        {dataPrint &&
          dataPrint.elements.map((element, index) => (
            <TableTr key={index}>
              <TableTd width="70%" align="left">
                {element.description}
              </TableTd>
              <TableTd width="30%" align="center">
                {dinheiroMask(element.total)}
              </TableTd>
            </TableTr>
          ))}
      </Table>

      <FootTotal>
        <div>
          SUBTOTAL:{' '}
          {dataPrint &&
            sumElementInArray(
              dataPrint.elements.map(
                (element) => element.total * element.amount
              )
            )}
        </div>
        <div>
          TOTAL TAXAS:{' '}
          {dataPrint &&
            sumFeesInArray(
              dataPrint.elements.map(
                (element) => element.total * element.amount
              )
            )}
        </div>
        <div>TOTAL PAGO: {dinheiroMask(dataPaymentBillet.amount)}</div>
      </FootTotal>

      <Divider />

      <Footer>
        <SubTitle>Obrigado pelo pagamento!</SubTitle>
        <Informations>
          Você receberá um link para baixar o comprovante de quitação por
          e-mail.
        </Informations>
      </Footer>
    </ContainerReceiptGenerated>
  );
};

export default ReceiptGenerated;
