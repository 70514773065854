import React, { useEffect, useState } from 'react';
import useUtil from '../../contexts/UtilContext';

import { Container, Content, Text } from './styles';

const Message: React.FC = () => {
  const { data, show } = useUtil();

  return (
    <Container show={show}>
      <Content>
        <Text type={data.type}>{data.message}</Text>
      </Content>
    </Container>
  );
};

export default Message;
