import React, { useEffect, useState } from 'react';
import Phases from '../../../components/Phases';
import {
  BodyContainer,
  CardMachineCenter,
  MachineArrowRight,
  Container,
  MainTitle,
  SubMainTitle,
  StepBack,
  FooterContainer,
} from './styles';
import { IoChevronBackOutline } from 'react-icons/io5';
import PagSerguroSafe from '../../../assets/images/svg/pagseguro-safe.svg';
import { Icons } from '../../../assets/Icons';
import usePayment from '../../../contexts/PaymentContext';
import { StatusCreditSale } from '../../../services/webtef/types';
import { useNavigate } from 'react-router-dom';

function handleStepSubMainTitle(message?: string): JSX.Element {
  if (!message) {
    return (
      <img src={require('../../../assets/gif/loading.gif')} alt="...loading" />
    );
  }

  return <h2>{message}</h2>;
}

const InsertCard = () => {
  const { paymentBilletResponse, loadingPayment } = usePayment();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (
      paymentBilletResponse &&
      paymentBilletResponse.Comando === '5000' &&
      paymentBilletResponse.Msg
    ) {
      setMessage(paymentBilletResponse.Msg);
    }

    if (
      paymentBilletResponse &&
      (paymentBilletResponse.Comando === '2000' ||
        paymentBilletResponse.Comando === '2100') &&
      paymentBilletResponse.Status === StatusCreditSale.OK
    ) {
      navigate('/comprovante');
    }

    if (
      paymentBilletResponse &&
      (paymentBilletResponse.Comando === '2000' ||
        paymentBilletResponse.Comando === '2100') &&
      paymentBilletResponse.Status !== StatusCreditSale.OK
    ) {
      navigate('/erro-pagamento');
    }
  }, [paymentBilletResponse]);

  return (
    <Container>
      <Phases
        icons={[
          Icons.lock_white,
          Icons.pencil,
          Icons.lock_white,
          Icons.whiteCheck,
        ]}
        texts={['Identificação', 'Dados do boleto', 'Pagamento', '']}
        step={3}
      />
      <MainTitle>Continue na maquininha</MainTitle>
      <BodyContainer>
        <SubMainTitle>
          {handleStepSubMainTitle(loadingPayment ? '' : message)}
        </SubMainTitle>
        <CardMachineCenter>
          <MachineArrowRight />
        </CardMachineCenter>
        <StepBack>
          <IoChevronBackOutline /> Voltar
        </StepBack>
      </BodyContainer>
      <FooterContainer>
        <img src={PagSerguroSafe} alt="PagSerguroSafe" />
        <p>
          Se caso não conseguirmos processar todos os pagamentos, a operação
          será cancelada e os valores estornados.
        </p>
      </FooterContainer>
    </Container>
  );
};

export default InsertCard;
