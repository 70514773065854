import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';
import pc2vw from '../../utils/pc2vw';

export const Primary = styled.button<{ icon: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: ${pc2vw(649)};
  height: ${pc2vw(87)};
  background: linear-gradient(
      91.03deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    ${({ theme }) => theme.colors.primary};

  border: 3px solid ${({ theme }) => theme.colors.dark_outlined};

  box-shadow: ${({ theme }) => theme.box_shadow.level_one};
  border-radius: ${pc2vw(15)};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_tree)};
  line-height: ${pc2vw(27)};
  color: #212121;
  ${({ disabled, theme }) =>
    disabled
      ? `   
    box-shadow: ${theme.box_shadow.level_one};
    opacity: 0.4;
  `
      : `
  
  :hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      linear-gradient(
        91.03deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      ${theme.colors.primary};
  
    border: 3px solid ${theme.colors.dark_outlined};
  
    box-shadow: ${theme.box_shadow.level_one};
  }
  `}

  ${({ icon }) =>
    icon &&
    ` 
    justify-content: space-between;
    padding: 0 20px;
    `}
`;

export const Tertiary = styled.button<{ icon: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-image: linear-gradient(
    to right,
    #ffdb4a 0%,
    #ffd425 51%,
    ${({ theme }) => theme.colors.primary} 100%
  );
  width: ${pc2vw(649)};
  height: ${pc2vw(87)};

  border: 3px solid ${({ theme }) => theme.colors.dark_mid};

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  border-radius: ${pc2vw(15)};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;

  font-size: ${({ theme }) => pc2vw(theme.font_size.level_tree)};
  line-height: ${pc2vw(27)};

  ${({ icon }) =>
    icon &&
    ` 
    justify-content: space-between;
    padding: 0 20px;
    `}
`;

export const Secondary = styled.button<{ icon: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: ${pc2vw(649)};
  height: ${pc2vw(87)};

  border: 3px solid ${({ theme }) => theme.colors.dark_mid};

  filter: drop-shadow(${({ theme }) => theme.box_shadow.level_one});
  border-radius: ${pc2vw(15)};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_tree)};
  line-height: ${pc2vw(27)};
  background-color: ${({ theme }) => theme.colors.white_full};
  color: ${({ theme }) => theme.colors.dark_mid};

  ${({ disabled, theme }) =>
    disabled
      ? `   
    box-shadow: ${theme.box_shadow.level_one};
    opacity: 0.3;
  `
      : `
  
  :hover {
    background: rgba(0, 0, 0, 0.1);
  
    border: 3px solid ${theme.colors.dark_mid};
  
    box-shadow: ${theme.box_shadow.level_one};
  }
  `}

  ${({ icon }) =>
    icon &&
    ` 
    justify-content: space-between;
    padding: 0 20px;
    `}
`;

export const Quartenary = styled.button<{ icon: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: ${pc2vw(649)};
  height: ${pc2vw(87)};
  background: ${({ theme }) => theme.colors.white_full};

  border: 2px solid ${({ theme }) => theme.colors.dark_outlined};

  border-radius: ${pc2vw(15)};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_tree)};
  line-height: ${pc2vw(27)};
  color: #212121;
  ${({ disabled, theme }) =>
    disabled
      ? `   
    box-shadow: ${theme.box_shadow.level_one};
    opacity: 0.4;
  `
      : `
  
  :hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      linear-gradient(
        91.03deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      ${theme.colors.primary};
  
    border: 2px solid ${theme.colors.dark_outlined};
  
    box-shadow: ${theme.box_shadow.level_one};
  }
  `}

  ${({ icon }) =>
    icon &&
    ` 
    justify-content: space-between;
    padding: 0 20px;
    `}
`;

export const Icon = styled.img`
  width: ${px2vw(36)};
  height: ${px2vw(36)};
`;

export const Image = styled.img`
  width: ${px2vw(80)};
  height: ${px2vw(80)};
  object-fit: contain;
  padding: 5px 0;
`;
