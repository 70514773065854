import React, {
  FocusEventHandler,
  forwardRef,
  Ref,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';
import { cnpjMask, cpfMask } from '../../utils/formatMasks';
import { isValidCPF } from '../../utils/validations';
import { celularMask } from 'masks-br';
import { Container, InputText } from './styles';
import useUtil from '../../contexts/UtilContext';

type Props = {
  placeholder?: string;
  disabled?: boolean;
  rtl?: boolean;
  type:
    | 'cpf'
    | 'cnpj'
    | 'number'
    | 'email'
    | 'password'
    | 'name'
    | 'phone'
    | 'digitable_line';
  name: string;
  required?: boolean;
  value?: string | number | readonly string[];
  onChange: (a: string) => void;
  onFocus?: FocusEventHandler<any> | undefined;
  style?: React.CSSProperties;
  autoFocus?: boolean;
};

export type InputRefs = {
  setValues: (type: string, value: string) => void;
};

let cpf_schema = yup.object().shape({
  cpf: yup.string().required().min(14, 'Termine de digitar seu documento'),
});

const Input = forwardRef(
  (
    { type, onChange, autoFocus, onFocus, ...props }: Props,
    ref: Ref<InputRefs | any>
  ) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const { setDocumentIsError } = useUtil();

    useImperativeHandle(ref, () => ({
      setValues(type: string, value: string) {
        if (!value) {
          setValue('');
        }
        switch (type) {
          case 'cpf':
            setValue(cpfMask(value));
            onChange(cpfMask(value));
            try {
              if (cpfMask(value).length === 14 && !isValidCPF(cpfMask(value))) {
                throw new Error('Documento inválido');
              }
              cpf_schema.validateSync({
                cpf: cpfMask(value),
              });
              setError('');
            } catch (error: any) {
              value.length ? setError(error.message) : setError('');
            }
            break;
          case 'cnpj':
            setValue(cnpjMask(value));
            onChange(cnpjMask(value));
            break;
          case 'email':
            setValue(value);
            onChange(value);
            try {
              if (
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
              ) {
                throw new Error('E-mail inválido');
              }
              setError('');
            } catch (error: any) {
              value.length ? setError(error.message) : setError('');
            }
            break;
          case 'password':
            setValue(value);
            onChange(value);
            break;
          case 'name':
            setValue(value);
            onChange(value);
            break;
          case 'phone':
            setValue(value);
            onChange(value);

            try {
              if (celularMask(value).length < 15) {
                throw new Error('Número inválido');
              }
              setError('');
            } catch (error: any) {
              value.length ? setError(error.message) : setError('');
            }
            break;
          case 'digitable_line':
            setValue(value);
            onChange(value);
            break;
          default:
            break;
        }
      },
    }));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e) return;
      let value_input = e.target.value;

      switch (type) {
        case 'cpf':
          setValue(cpfMask(value_input));
          onChange(cpfMask(value_input));
          try {
            if (
              cpfMask(value_input).length === 14 &&
              !isValidCPF(cpfMask(value_input))
            ) {
              throw new Error('Documento inválido');
            }
            cpf_schema.validateSync({
              cpf: cpfMask(value_input),
            });
            setError('');
            if(isValidCPF(cpfMask(value_input))) {
              setDocumentIsError(false)
            }
          } catch (error: any) {
            setDocumentIsError(true);
            value_input.length ? setError(error.message) : setError('');
          }
          break;
        case 'cnpj':
          setValue(cnpjMask(value_input));
          onChange(cnpjMask(value_input));
          break;
        case 'email':
          setValue(value_input);
          onChange(value_input);
          try {
            if (
              !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value_input)
            ) {
              throw new Error('E-mail inválido');
            }
            setError('');
          } catch (error: any) {
            value_input.length ? setError(error.message) : setError('');
          }
          break;
        case 'password':
          setValue(value_input);
          onChange(value_input);
          break;
        case 'name':
          setValue(value_input);
          onChange(value_input);
          break;
        case 'phone':
          setValue(value_input);
          onChange(value_input);

          try {
            if (celularMask(value_input).length < 15) {
              throw new Error('Número inválido');
            }
            setError('');
          } catch (error: any) {
            value_input.length ? setError(error.message) : setError('');
          }
          break;
        case 'digitable_line':
          setValue(value_input);
          onChange(value_input);
          break;
        default:
          break;
      }
    };

    return (
      <Container>
        <InputText
          autoFocus={autoFocus}
          type={type}
          error={!!error}
          onChange={handleChange}
          value={value}
          onFocus={onFocus}
          {...props}
        />
        <div style={{ color: 'red' }}>{error && error.toUpperCase()}</div>
      </Container>
    );
  }
);

export default Input;
