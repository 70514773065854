import { IField, User } from "../services/parcelamos/types";
import Environment from "./Environment";
import CondicaoPagamento from "../assets/jsons/condicao_pagamento.json";

function getUser(): User {
  const user_data = sessionStorage.getItem("@parcelamos/sign-in");
  const user = user_data && (JSON.parse(user_data) as User);
  return user ? user : ({} as User);
}

const paymentCalc = (fieldValue: IField): PaymentCalc => {
  const environment = new Environment();

  const user = getUser();

  let condicaoPagamento = CondicaoPagamento.condicao_pagamento;

  if (user) {
    condicaoPagamento = user.condicao_pagamento;
  }

  if (!condicaoPagamento) {
    return {
      bankslip_value: 0,
      payment_amount: 0,
      total_value_difference: 0,
      difference_installment_value: 0,
      installment_amount_interest: 0,
      installments_number: 0,
    };
  }

  const coeficiente_final = condicaoPagamento.find(
    (item) =>
      item.id_forma_pagamento === fieldValue.payment_form &&
      item.qtde_parcelas === fieldValue.installments_number
  );

  if (!coeficiente_final) {
    return {
      bankslip_value: 0,
      payment_amount: 0,
      total_value_difference: 0,
      difference_installment_value: 0,
      installment_amount_interest: 0,
      installments_number: 0,
    };
  }

  const payment_amount =
    fieldValue.bankslip_value * coeficiente_final.coeficiente_final;

  const installment_amount_interest =
    payment_amount / coeficiente_final.qtde_parcelas;
  const valor_parcela_limpo =
    fieldValue.bankslip_value / coeficiente_final.qtde_parcelas;

  const difference_installment_value =
    installment_amount_interest - valor_parcela_limpo;
  const total_value_difference = payment_amount - fieldValue.bankslip_value;

  return {
    bankslip_value: fieldValue.bankslip_value,
    payment_amount,
    total_value_difference,
    difference_installment_value,
    installment_amount_interest,
    installments_number: coeficiente_final.qtde_parcelas,
  };
};

export default paymentCalc;

export interface PaymentCalc {
  bankslip_value: number;
  payment_amount: number;
  total_value_difference: number;
  difference_installment_value: number;
  installment_amount_interest: number;
  installments_number: number;
}
