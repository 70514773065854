type layoutType = {
  default: string[];
  default_two: string[];
  shift: string[];
  number: string[];
  only_letters: string[];
  only_letters_shift: string[];
  default_with_number: string[];
  default_with_number_shift: string[];
};

export const layout: layoutType = {
  default: [
    'q w e r t y u i o p',
    'a s d f g h j k l ç',
    'z x c v b n m {bksp}',
    '. _ - @ .com.br .com',
    '{space}',
  ],
  default_with_number: [
    '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    'q w e r t y u i o p [ ] \\',
    "a s d f g h j k l ; '",
    '{shift} z x c v b n m , . /',
    '@ {space}',
  ],
  default_with_number_shift: [
    '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
    'Q W E R T Y U I O P { } |',
    'A S D F G H J K L Ç : "',
    '{shift} Z X C V B N M &lt; &gt; ?',
    '@ {space}',
  ],
  default_two: [
    'Q W E R T Y U I O P',
    'A S D F G H J K L Ç',
    'Z X C V B N M {bksp}',
    '{space}',
  ],
  shift: [
    'Q W E R T Y U I O P',
    'A S D F G H J K L Ç',
    'Z X C V B N M {bksp}',
    '. _ - @ .COM.BR .COM',
    '{space}',
  ],
  number: ['1 2 3', '4 5 6', '7 8 9', ' 0 {bksp}'],
  only_letters_shift: [
    'Q W E R T Y U I O P',
    'A S D F G H J K L Ç',
    'Z X C V B N M {bksp}',
    '{space}',
  ],
  only_letters: [
    'q w e r t y u i o p',
    'a s d f g h j k l ç',
    'z x c v b n m {bksp}',
    '{space}',
  ],
};
