import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes as Switch,
  Navigate,
} from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import Ready from '../pages/Login/ready';
import PublicRoute from './PublicRoute';
import BillingData from '../pages/BillingData';
import PrivateRoute from './PrivateRoute';

import Login from '../pages/Login';
import Home from '../pages/Home';

import InsertCard from '../pages/Payment/Processing/InsertCard';
import Processing from '../pages/Payment/Processing/Processing';
import ErrorPayment from '../pages/Payment/Processing/ErrorPayment';
import Receipt from '../pages/Receipt';
import Sms from '../pages/Receipt/Sms';
import Email from '../pages/Receipt/Email';
import Success from '../pages/Receipt/Success';
import Printer from '../pages/Receipt/Printer';
import Identification from '../pages/Identification';
import Agreements from '../pages/Agreements';

const Routes: React.FC = () => {
  const { user } = useAuth();

  return (
    <BrowserRouter>
      <Switch>
        <Route
          element={
            <PublicRoute authenticated={!!(user && user.authenticated)} />
          }
        >
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Route>
        <Route
          element={
            <PrivateRoute authenticated={!!(user && user.authenticated)} />
          }
        >
          <Route path="/configurando" element={<Ready />} />
          <Route path="/inicial" element={<Home />} />
          <Route path="/identificacao" element={<Identification />} />
          <Route path="/pagamento-boleto" element={<BillingData />} />

          <Route path="/comprovante" element={<Receipt />} />
          <Route path="/comprovante-sms" element={<Sms />} />
          <Route path="/comprovante-email" element={<Email />} />
          <Route path="/comprovante-sucesso" element={<Success />} />
          <Route path="/comprovante-impressao" element={<Printer />} />

          <Route path="/inserir-cartao" element={<InsertCard />} />
          <Route path="/processando" element={<Processing />} />
          <Route path="/erro-pagamento" element={<ErrorPayment />} />

          <Route path="/convenios" element={<Agreements />} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
