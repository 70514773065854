import React from "react";
import { Loading } from "../../../components/Loading";
import { ContainerProcessing, SubTitleInfo, TitleInfo } from "./styles";

const ProcessingInfo: React.FC = () => {
  return (
    <ContainerProcessing>
      <TitleInfo>Processando</TitleInfo>
      <SubTitleInfo>Estamos processando o pagamento</SubTitleInfo>
      <Loading />
    </ContainerProcessing>
  );
};

export default ProcessingInfo;
