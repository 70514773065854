import styled, { css, keyframes } from "styled-components";
import px2vw from "../../../utils/pc2vw";

const grow = keyframes`
  0% {
   
    transform: scale(0.5);
    opacity: 0.5;
   
  }
  
  100% {
    
    transform: scale(1);
    opacity: 1;
  }
`;

const ungrow = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
   
  }
  
  100% {
    
    transform: scale(0);
    opacity: 1;
   
  }
`;

export const Container = styled.div<{ open: boolean | undefined }>`
  background: ${({ theme }) => theme.colors.white_full};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  gap: ${px2vw(25)};
  position: absolute;
  top: 0;
  width: ${px2vw(770)};
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(20px);
  z-index: 9999;
  overflow: hidden;

  ${({ open }) => {
    const check = typeof open === "boolean";
    if (!check) {
      return css`
        display: none;
      `;
    }
    if (check && open) {
      return css`
        animation: ${grow} 0.2s linear both;
      `;
    }
    if (check && !open) {
      return css`
        animation: ${ungrow} 0.2s linear both;
      `;
    }
  }}
`;

export const MainTitle = styled.h1`
  font-size: ${({ theme }) => px2vw(theme.font_size.level_eight)};
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: ${({ theme }) => theme.font_size.level_eight};
  line-height: ${px2vw(58)};
  color: ${({ theme }) => theme.colors.dark_full};

  text-align: center;
  margin-bottom: ${px2vw(78)};
`;

export const SubTitle = styled.p`
  font-size: ${({ theme }) => px2vw(theme.font_size.level_tree)};
  font-weight: 300;
  margin: 4vh 0;
`;

export const TextButton = styled.p`
  font-size: ${({ theme }) => px2vw(theme.font_size.level_tree)};
  font-weight: 200;
`;

export const SubTextButton = styled.p`
  font-size: ${({ theme }) => px2vw(theme.font_size.level_tree)};
  font-weight: 200;
`;

export const TextButtonBold = styled.p`
  font-size: ${({ theme }) => px2vw(theme.font_size.level_tree)};
  font-weight: 700;
`;

export const ContainerGridButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${px2vw(12)};
`;

export const ContainerButton = styled.div`
  width: 40%;
`;

export const ContainerInsideButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const ContainerButtonInside = {
  display: "flex",
  width: px2vw(304),
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

export const StepBackText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font_size.level_tree};
  line-height: ${px2vw(27)};

  color: ${({ theme }) => theme.colors.dark_mid};
  z-index: 1;
`;

export const StepBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(20)} 0px;
  gap: ${px2vw(4)};
  width: 100%;
  height: ${px2vw(67)};
  appearance: none;
  outline: none;
  border: none;
  background: none;

  &.padding-100 {
    padding: ${px2vw(100)} 0px;
  }
`;

export const StepBackIcon = styled.img``;
