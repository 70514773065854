import React, { useEffect, useRef, useState } from "react";

import "react-simple-keyboard/build/css/index.css";
import Button from "../../components/Button";
import Input, { InputRefs } from "../../components/Input";
import Keyboard from "react-simple-keyboard";
import Phases from "../../components/Phases";

import {
  Container,
  Content,
  Form,
  Text,
  Row,
  StepBack,
  StepBackIcon,
  StepBackText,
  KeyboardView,
} from "./styles";
import { Icons } from "../../assets/Icons";
import { GetDebitsRequest, ScreenTypes } from ".";
import { useAuth } from "../../contexts/AuthContext";
import usePayment from "../../contexts/PaymentContext";
import { layout } from "../../assets/keyboard";

type Form = {
  [index: string]: any;
  required?: boolean;
};

type Props = {
  goToScreen: (name: ScreenTypes) => void;
  fetchDebits: (data: GetDebitsRequest) => void;
  goBack: () => void;
};

const FetchDebits: React.FC<Props> = ({ goToScreen, fetchDebits, goBack }) => {
  const { user } = useAuth();
  const { dataPaymentBillet } = usePayment();
  const [values, setValues] = useState<Form>([]);
  const [inputSelected, setInputSelected] = useState<{
    index: number;
    input_key: string;
  }>();
  const [shift, setShift] = useState(false);

  function onKeyPress(key: string) {
    if (key === "{shift}") {
      setShift(!shift);
    }
  }

  function getFieldsByIdPartner() {
    if (!user) {
      return [];
    }
    return user.partner_fields.filter(
      (field) => field.id_partner === dataPaymentBillet.id_partner
    );
  }

  return (
    <Container>
      <Content>
        <Phases
          icons={[Icons.lock, Icons.pencil, Icons.lock, Icons.whiteCheck]}
          texts={["Identificação", "Dados do pagamento", "Pagamento", ""]}
          step={2}
        />
        <Form>
          <Text>
            Vamos localizar
            <br />
            os dados:
          </Text>
          <Row>
            {getFieldsByIdPartner().map((field, index) => {
              return (
                <Input
                  key={index}
                  autoFocus={index === 0}
                  placeholder={field.label}
                  name={field.input_key}
                  type="digitable_line"
                  onChange={(a) =>
                    setValues((oldValues) => ({
                      ...oldValues,
                      [field.input_key]: a,
                    }))
                  }
                  value={values ? values[field.input_key] : ""}
                  onFocus={() =>
                    setInputSelected({ index, input_key: field.input_key })
                  }
                  rtl
                  required
                />
              );
            })}

            <Button
              text="Continuar"
              variant="primary"
              onClick={() => fetchDebits(values)}
            />
          </Row>
          <StepBack onClick={goBack}>
            <StepBackIcon src={Icons.chevron_left} />
            <StepBackText>Voltar</StepBackText>
          </StepBack>
        </Form>
        <KeyboardView>
          <Keyboard
            onKeyPress={onKeyPress}
            debug
            inputName={inputSelected ? inputSelected.input_key : "name"}
            key={inputSelected ? inputSelected.input_key : "name"}
            onChange={(e) => {
              inputSelected &&
                setValues((oldValues) => ({
                  ...oldValues,
                  [inputSelected.input_key]: e,
                }));
              console.log(e);
            }}
            layoutName={
              shift ? "default_with_number_shift" : "default_with_number"
            }
            layout={layout}
            theme={"hg-theme-default hg-layout-default myTheme"}
            display={{
              "{bksp}": "⌫",
              "{space}": "␣",
              "{shift}": "⇧",
            }}
            buttonTheme={[
              {
                class: "hg-black",

                buttons:
                  "' " +
                  '` 1 2 3 4 5 6 7 8 9 0 - = {bksp} q w e r t y u i o p [ ] \\ a s d f g h j k l ;  {shift} z x c v b n m , . / .com @ {space} ~ ! @ # $ % ^ &amp; * ( ) _ + Q W E R T Y U I O P { } | A S D F G H J K L Ç : " Z X C V B N M &lt; &gt; ?',
              },
            ]}
          />
        </KeyboardView>
      </Content>
    </Container>
  );
};

export default FetchDebits;
