export default class reCaptcha {
  static getRecaptchaToken(action: "GET_BILLET" | "CHECKOUT"): Promise<string> {
    if (!process.env.REACT_APP_PARCELAMOS_RECAPTCHA_TOKEN) {
      return new Promise((res) => res("AMBIENTE_DE_TESTE"));
    }

    return new Promise((res, rej) => {
      // @ts-ignore
      grecaptcha.enterprise.ready(function() {
        // @ts-ignore
        grecaptcha.enterprise
          .execute(process.env.REACT_APP_PARCELAMOS_RECAPTCHA_TOKEN, {
            action: "LOGIN", // LOGIN RECAPTCHA
          })
          .then(function(token: string) {
            res(token);
          })
          .catch((err: Error) => {
            rej(err);
          });
      });
    });
  }
}
