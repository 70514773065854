import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import usePayment from '../../../contexts/PaymentContext';
import parcelamos from '../../../services/parcelamos';
import { ContainerEmail, KeyboardView, QuestionTitle } from './styles';
import { v4 as uuidv4 } from 'uuid';
import useUtil from '../../../contexts/UtilContext';
import Keyboard from 'react-simple-keyboard';
import { layout } from '../../../assets/keyboard';
import { dinheiroMask } from 'masks-br';

const ReceiptEmail: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const navigate = useNavigate();
  const { dataPaymentBillet } = usePayment();
  const { showMessage } = useUtil();

  async function handleSendComprovanteEmail() {
    try {
      await parcelamos.notification({
        send_to_email: true,
        send_to_sms: false,
        nome: dataPaymentBillet.name,
        email,
        boletos: dataPaymentBillet.billets.map((billet) => {
          return {
            cod_boleto: billet.codigo_barras,
            valor: dinheiroMask(billet.valor_boleto.toString()),
            liquidado_em: billet.data_pagamento
              ? new Date(billet.data_pagamento)
              : undefined,
            liquidacao_id: dataPaymentBillet.payment_identifier || uuidv4(),
          };
        }),
      });
      return true;
    } catch (err) {
      showMessage({
        type: 'error',
        message: 'Erro ao enviar o comprovante por e-mail',
      });
      return false;
    }
  }

  async function handleSendComprovanteEmailAndContinue() {
    if (!handleSendComprovanteEmail()) navigate('/comprovante');

    navigate('/comprovante-sucesso');
  }
  return (
    <ContainerEmail>
      <QuestionTitle>Qual e-mail enviamos o comprovante?</QuestionTitle>
      <Input
        name="email"
        type="email"
        value={email}
        onChange={setEmail}
        placeholder="Seu melhor e-mail"
      />
      <Button
        text="Continuar"
        variant="primary"
        onClick={() => handleSendComprovanteEmailAndContinue()}
      />
      <KeyboardView>
        <Keyboard
          onChange={(email) => setEmail(email)}
          layoutName="default"
          layout={layout}
          theme={'hg-theme-default hg-layout-default myTheme'}
          display={{
            '{bksp}': '⌫',
            '{space}': '␣',
          }}
          buttonTheme={[
            {
              class: 'hg-black',
              buttons:
                'q w e r t y u i o p a s d f g h j k l ç z x c v b n m {bksp} . _ - @ .com.br .com {space}',
            },
          ]}
        />
      </KeyboardView>
    </ContainerEmail>
  );
};

export default ReceiptEmail;
