import React from 'react';
import * as dotenv from 'dotenv';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';
import Global from './global';
import { AuthProvider } from './contexts/AuthContext';
import Message from './components/Message';
import { UtilProvider } from './contexts/UtilContext';
import { ptBR } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import LoadingScreen from './components/LoadingScreen';
import { PaymentProvider } from './contexts/PaymentContext';

setDefaultOptions({ locale: ptBR });
dotenv.config({ path: './.env' });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Global />
    <UtilProvider>
      <PaymentProvider>
        <ThemeProvider theme={theme}>
          <LoadingScreen />
          <AuthProvider>
            <Message />
            <App />
          </AuthProvider>
        </ThemeProvider>
      </PaymentProvider>
    </UtilProvider>
  </React.StrictMode>
);
