import React, { memo } from 'react';
import * as S from './styles';
import { Icons } from '../../assets/Icons';

type Props = {
  icons: string[];
  step?: number;
  texts: string[];
};
const Phases: React.FC<Props> = ({ icons, step = 0, texts }) => {
  if (!icons || icons.length !== 4) {
    throw new Error('Icons not defined');
  }

  return (
    <S.Container>
      <S.StepContainer>
        {icons.map((icon, index) => {
          return (
            <S.Step key={index}>
              <S.StepInternalOne>
                <S.StepContent step={step} index={index}>
                  <S.Icon src={icon} alt="icons" />
                </S.StepContent>
                {index < icons.length - 1 && (
                  <S.TimeLine step={step} index={index} />
                )}
              </S.StepInternalOne>
              {index < icons.length - 1 && (
                <S.StepInternalTwo>
                  <S.DisableTextHeader>Etapa {index + 1}</S.DisableTextHeader>
                  {index !== icons.length && (
                    <S.DisableTextContent>{texts[index]}</S.DisableTextContent>
                  )}
                </S.StepInternalTwo>
              )}
            </S.Step>
          );
        })}
      </S.StepContainer>
      <S.Divider />
    </S.Container>
  );
};

export default memo(Phases);
