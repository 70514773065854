import styled, { css, keyframes } from 'styled-components';
import px2vw from '../../utils/pc2vw';

const goback = keyframes`
  0% {
    transform: translate(0px,-50px);
    opacity: 0.6;
   
  }
  
  100% {
    
    transform: translate(0);
    opacity: 1;
  }
`;

const grow = keyframes`
  0% {
   
    transform: scale(0.5);
    opacity: 0.5;
   
  }
  
  100% {
    
    transform: scale(1);
    opacity: 1;
  }
`;

const ungrow = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
   
  }
  
  100% {
    
    transform: scale(0);
    opacity: 1;
   
  }
`;

export const Container = styled.div`
  width: ${px2vw(768)};
  height: 100%;
  align-items: center;
  background: ${({ theme }) => theme.colors.white_full};
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 0px;

  width: ${px2vw(768)};
  height: 100%;
`;

export const TextHead = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-size: ${px2vw(48)};
  line-height: ${px2vw(58)};
  text-align: center;

  color: ${({ theme }) => theme.colors.dark_full};
`;

export const TextBody = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: ${px2vw(30)};
  line-height: ${px2vw(36)};
  text-align: center;
  color: ${({ theme }) => theme.colors.dark_mid};
`;

export const TextDigitableLine = styled.span`
  width: auto;
  height: ${px2vw(27)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.font_size.level_tree};
  line-height: ${px2vw(27)};

  color: ${({ theme }) => theme.colors.primary};
`;

export const KeybordImage = styled.img`
  width: ${px2vw(36)};
  height: ${px2vw(36)};
`;

export const MessageContainerBankslip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 0px;
  gap: ${px2vw(20)};

  width: ${px2vw(766)};
  height: auto;
  max-height: ${px2vw(900)};
  overflow-y: scroll;
`;

export const MessageContainerRead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(24)};

  width: ${px2vw(766)};
  height: ${px2vw(280)};
  z-index: 2;
`;

export const DigitableLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(20)} ${px2vw(80)};
  gap: ${px2vw(15)};

  width: ${px2vw(766)};
  height: ${px2vw(76)};
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(20)};

  width: ${px2vw(766)};
  height: ${px2vw(280)};
`;

export const ArrowsImage = styled.img`
  position: absolute;
  width: ${px2vw(535.61)};
  height: ${px2vw(535.61)};
  right: 0;
  bottom: 0;
  z-index: 1;

  animation: ${goback} 0.3s ease-in-out alternate infinite;
`;

export const StepBackText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font_size.level_tree};
  line-height: ${px2vw(27)};

  color: ${({ theme }) => theme.colors.dark_mid};
  z-index: 1;
`;

export const StepBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: ${px2vw(20)} 0px;
  gap: ${px2vw(4)};
  width: 100%;
  height: ${px2vw(67)};
  appearance: none;
  outline: none;
  border: none;
  background: none;
  z-index: 2;

  &.padding-100 {
    padding: ${px2vw(100)} 0px;
  }
`;

export const StepBackIcon = styled.img``;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px ${px2vw(60)};
  gap: ${px2vw(30)};

  width: ${px2vw(769)};
  height: auto;
`;

export const Text = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-size: ${px2vw(58)};
  line-height: ${px2vw(70)};
  text-align: center;

  color: #212121;
`;

export const Row = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: ${px2vw(24)};

  width: ${px2vw(769)};
  height: ${px2vw(318)};
`;

export const ReadCodeTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 15px;

  width: ${px2vw(768)};
  height: ${px2vw(76)};
`;

export const ButtonPayment = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1;
`;

export const Readmage = styled.img``;

export const ContainerDelete = styled.div<{ open: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(50)};
  position: absolute;
  top: 0;
  width: ${px2vw(770)};
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(20px);
  z-index: 9999;
  overflow: hidden;

  ${({ open }) => {
    const check = typeof open === 'boolean';
    if (!check) {
      return css`
        display: none;
      `;
    }
    if (check && open) {
      return css`
        animation: ${grow} 0.2s linear both;
      `;
    }
    if (check && !open) {
      return css`
        animation: ${ungrow} 0.2s linear both;
      `;
    }
  }}
`;

export const ContentDelete = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(50)};

  width: ${px2vw(628)};
  height: ${px2vw(959)};
  overflow: hidden;
`;

export const DeleteText = styled.span`
  width: ${px2vw(628)};
  height: ${px2vw(116)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-size: ${px2vw(48)};
  line-height: ${px2vw(58)};
  text-align: center;
  color: ${({ theme }) => theme.colors.dark_full};
`;

export const DeleteData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(30)};
  width: ${px2vw(568)};
  height: ${px2vw(384)};
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(30)};

  width: ${px2vw(586)};
  height: ${px2vw(384)};
`;

export const BarcodeImage = styled.img`
  width: ${px2vw(44)};
  height: ${px2vw(44)};
`;

export const InformationHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(30)};

  width: ${px2vw(586)};
  height: ${px2vw(68)};
`;

export const InformationHeadItens = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(54)};

  width: ${px2vw(512)};
  height: ${px2vw(68)};
  left: ${px2vw(74)};
  top: 0px;
`;

export const InformationTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(10)};

  width: ${px2vw(415)};
  height: ${px2vw(68)};
`;

export const InformationHeadText = styled.text`
  width: ${px2vw(415)};
  height: ${px2vw(22)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_four)};
  line-height: ${px2vw(22)};

  color: #212121;
`;

export const InformationHeadAmount = styled.text`
  width: ${px2vw(152)};
  height: ${px2vw(36)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_two)};
  line-height: ${px2vw(36)};

  color: #212121;
`;

export const InformationBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(30)};

  width: ${px2vw(586)};
  height: ${px2vw(286)};
`;

export const InformationBodyItens = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(10)};

  width: ${px2vw(586)};
  height: ${px2vw(54)};

  border-radius: ${px2vw(10)};
`;

export const InformationBodyIten = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(10)};

  width: ${px2vw(586)};
  height: ${px2vw(22)};

  border-radius: ${px2vw(10)};
`;

export const InformationBoldText = styled.text<{ size: number }>`
  width: ${({ size }) => px2vw(size)};
  height: ${px2vw(22)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_four)};
  line-height: ${px2vw(22)};

  color: rgba(33, 33, 33, 0.5);
`;

export const DotDivider = styled.text`
  width: ${px2vw(5)};
  height: ${px2vw(5)};

  background: rgba(33, 33, 33, 0.25);
`;

export const InformationNormalText = styled.text`
  width: ${px2vw(269)};
  height: ${px2vw(22)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_four)};
  line-height: ${px2vw(22)};

  color: rgba(33, 33, 33, 0.5);
  overflow-wrap: break-word;
`;

export const DeleteButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(20)};
  width: ${px2vw(628)};
  height: ${px2vw(87)};
`;

export const FragmentDiv = styled.div``;

export const InputAbsolute = styled.input`
  position: absolute;
  bottom: 9999px;
`;
