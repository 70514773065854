import styled from "styled-components";
import px2vw from "../../utils/pc2vw";

export const Container = styled.div`
  width: ${px2vw(768)};
  height: 100%;
  background: white;
  overflow: hidden;
`;

export const Text = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: ${px2vw(58)};
  line-height: ${px2vw(70)};
  text-align: center;

  color: #212121;

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(200)};

  width: ${px2vw(769)};
  height: 100%; //replace;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 60px;
  gap: ${px2vw(50)};

  width: ${px2vw(769)};
  height: ${px2vw(318)};
`;

export const Row = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: ${px2vw(24)};

  width: ${px2vw(769)};
  height: ${px2vw(318)};
`;

export const KeyboardView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(20)};

  width: ${px2vw(769)};
  height: ${px2vw(367)};

  .simple-keyboard.hg-theme-default.myTheme {
    border-radius: 10px;
    width: calc(100% - 20px);
    background-color: #ffffff;
  }

  .simple-keyboard.hg-theme-white.myTheme {
    border-radius: 10px;
    margin: ${px2vw(10)};
    width: calc(100% - 20px);
    background-color: #ffffff;
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-black {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${px2vw(10)};
    gap: ${px2vw(10)};

    width: ${px2vw(58.5)};
    height: ${px2vw(76.75)};
    margin: ${px2vw(5)};
    background: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: ${px2vw(30)};
    line-height: ${px2vw(36)};
    text-align: center;

    color: #000;
  }
`;

export const LoadingMessageComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${px2vw(20)};
  margim-top: -50px;

  width: ${px2vw(769)};
`;

export const ReadyTextHeader = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-size: ${px2vw(45)};
  line-height: 58px;
  text-align: center;

  color: ${({ theme }) => theme.colors.white_full};
`;

export const ReadyTextBody = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-size: ${px2vw(27)};
  text-align: center;

  color: ${({ theme }) => theme.colors.white_full};
`;

export const StepBack = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(20)} 0px;
  gap: ${px2vw(4)};
  width: 100%;
  height: ${px2vw(67)};
  appearance: none;
  outline: none;
  border: none;
  background: none;
`;

export const StepBackIcon = styled.img``;

export const StepBackText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font_size.level_two};
  line-height: ${px2vw(27)};

  color: ${({ theme }) => theme.colors.dark_mid};
  z-index: 1;
`;
