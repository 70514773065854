import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 80px;
  gap: 15px;
  background-color: ${({ theme }) => theme.colors.white_full};
  border: none;
  color: ${({ color }) => color};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_tree)};
  line-height: ${px2vw(27)};
  width: ${px2vw(710)};
  height: ${px2vw(76)};
`;

export const Icon = styled.img`
  width: ${px2vw(36)};
  height: ${px2vw(36)};
`;
