import React, { useState } from 'react';

import { Container } from './styles';

import ReadBankslip from './readBankslip';
import DigitableLine from './digitableLine';
import Bankslips from './bankslips';
import parcelamos from '../../services/parcelamos';
import { BilletResponseType } from '../../services/parcelamos/types';
import useUtil from '../../contexts/UtilContext';
import usePayment from '../../contexts/PaymentContext';

export enum ScreenTypes {
  Leitor = 1,
  Digitavel = 2,
  Boletos = 3,
}

export type BilletType = BilletResponseType;

const BillingData: React.FC = () => {
  const { showMessage, loadingScreen } = useUtil();
  const { dataPaymentBillet, setPaymentBilletData } = usePayment();

  const [step, setStep] = useState(1);

  const goToScreen = (name: ScreenTypes) => {
    setStep(name);
  };

  const goBack = () => {
    setStep(1);
  };

  const getBilletData = async (billet_code: string) => {
    loadingScreen(true);
    const contains = dataPaymentBillet.billets.find(
      (billets) =>
        billets.linha_digitavel === billet_code ||
        billets.codigo_barras === billet_code
    );

    if (contains) {
      showMessage({
        type: 'error',
        message: 'Ops, esse boleto já foi adicionado',
      });

      loadingScreen(false);
      setStep(ScreenTypes.Boletos);
      loadingScreen(false);
      return;
    }
    const response = await parcelamos.getBillet({ billet_code });
    if (response.error || response.message) {
      loadingScreen(false);
      showMessage({ type: 'error', message: response.message! });
      return;
    }

    if (response.payed) {
      loadingScreen(false);
      showMessage({
        type: 'error',
        message: 'Desculpe, esse boleto já esta pago.',
      });
      return;
    }
    setPaymentBilletData({
      ...dataPaymentBillet,
      billets: [...dataPaymentBillet.billets, response],
    });
    setStep(ScreenTypes.Boletos);
    loadingScreen(false);
  };

  const removeBillet = (index: number) => {
    const billets = dataPaymentBillet.billets;
    const arr = billets.filter((_, idx) => idx !== index);
    setPaymentBilletData({ ...dataPaymentBillet, billets: arr });
  };

  const steps = [
    {
      id: 1,
      children: (
        <ReadBankslip
          key={1}
          goToScreen={goToScreen}
          fetchBillet={getBilletData}
        />
      ),
    },
    {
      id: 2,
      children: (
        <DigitableLine
          key={2}
          goToScreen={goToScreen}
          fetchBillet={getBilletData}
          goBack={goBack}
        />
      ),
    },
    {
      id: 3,
      children: (
        <Bankslips
          key={3}
          goToScreen={goToScreen}
          removeBillet={removeBillet}
          goBack={goBack}
        />
      ),
    },
  ];

  return <Container>{steps.map((s) => s.id === step && s.children)}</Container>;
};

export default BillingData;
