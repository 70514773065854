import React, { createContext, ReactNode, useState } from "react";

interface ModalContextData {
  showMessage: ({ type, message }: TypeMessage) => void;
  data: TypeMessage;
  show: boolean;
  loading: boolean;
  loadingScreen: (loading: boolean) => void;
  documentIsError: boolean;
  setDocumentIsError: (error: boolean) => void;
}

export type TypeMessage = {
  type: "error" | "info" | "success";
  message: string;
  time?: number;
};

const UtilContext = createContext<ModalContextData>({} as ModalContextData);

export const UtilProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<TypeMessage>({
    type: "info",
    message: "",
    time: 3000,
  });
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [documentIsError, setDocumentIsError] = useState<boolean>(false);

  function showMessage({ type, message, time = 3000 }: TypeMessage) {
    setData({ type, message });
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, time);
  }

  function loadingScreen(start: boolean) {
    setLoading(start);
  }

  return (
    <UtilContext.Provider
      value={{
        showMessage,
        data,
        show,
        loadingScreen: loadingScreen,
        loading,
        documentIsError,
        setDocumentIsError,
      }}
    >
      {children}
    </UtilContext.Provider>
  );
};

const useUtil = () => React.useContext(UtilContext);

export default useUtil;
