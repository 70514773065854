import React from 'react';
import * as S from './styles';

export const Loading = () => {
  return (
    <S.Container>
      <S.Image src={require('../../assets/gif/loading.gif')} alt="loading..." />
    </S.Container>
  );
};
