import { format } from 'date-fns';
import { bankslipMask, dinheiroMask } from 'masks-br';
import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { BilletType } from '.';
import { Icons } from '../../assets/Icons';
import Button from '../../components/Button';
import { formatShortToDateString } from '../../utils/utils';

import {
  BarcodeImage,
  ContainerDelete,
  ContentDelete,
  DeleteButtons,
  DeleteData,
  DeleteText,
  DotDivider,
  InformationBody,
  InformationBodyIten,
  InformationBodyItens,
  InformationBoldText,
  InformationContainer,
  InformationHead,
  InformationHeadAmount,
  InformationHeadItens,
  InformationHeadText,
  InformationNormalText,
  InformationTextContent,
} from './styles';

type Props = {
  billet: BilletType;
  removeBillet: () => void;
};

export type DeleteBilletRefs = {
  handleOpen: () => void;
};

const DeleteBillet = forwardRef((props: Props, ref: Ref<DeleteBilletRefs>) => {
  const { billet, removeBillet } = props;
  const [open, setOpen] = useState<boolean | undefined>();

  useImperativeHandle(ref, () => ({
    handleOpen() {
      onShow();
    },
  }));

  function onShow() {
    setOpen(!open);
  }

  function handleDelete() {
    removeBillet();
    onShow();
  }

  return (
    <ContainerDelete open={open}>
      <ContentDelete>
        <DeleteText>Quer mesmo excluir esse boleto?</DeleteText>
        <DeleteData>
          <InformationContainer>
            <InformationHead>
              <BarcodeImage src={Icons.barcode} />
              <InformationHeadItens>
                <InformationTextContent>
                  <InformationHeadText>{billet.cedente}</InformationHeadText>
                  <InformationHeadAmount>
                    {dinheiroMask(billet.valor_boleto)}
                  </InformationHeadAmount>
                </InformationTextContent>
              </InformationHeadItens>
            </InformationHead>
            <InformationBody>
              <InformationBodyItens>
                <InformationBodyIten>
                  <InformationBoldText size={107}>
                    Vencimento
                  </InformationBoldText>
                  <DotDivider />
                  <InformationNormalText>
                    {formatShortToDateString(billet.data_vencimento)}
                  </InformationNormalText>
                </InformationBodyIten>
                <InformationBodyIten>
                  <InformationBoldText size={128}>
                    Nosso número
                  </InformationBoldText>
                  <DotDivider />
                  <InformationNormalText>
                    {billet.id_boleto}
                  </InformationNormalText>
                </InformationBodyIten>
              </InformationBodyItens>

              <InformationBodyItens></InformationBodyItens>
              <InformationBodyItens>
                <InformationBodyIten>
                  <InformationBoldText size={128}>
                    Linha digitável
                  </InformationBoldText>
                  <DotDivider />
                  <InformationNormalText>
                    {bankslipMask(billet.linha_digitavel)}
                  </InformationNormalText>
                </InformationBodyIten>
              </InformationBodyItens>

              <InformationBodyItens>
                <InformationBodyIten>
                  <InformationBoldText size={95}>
                    Instruções
                  </InformationBoldText>
                  <DotDivider />
                  <InformationNormalText>
                    {billet.mensagem ? billet.mensagem : 'Sem informaçôes'}
                  </InformationNormalText>
                </InformationBodyIten>
              </InformationBodyItens>
            </InformationBody>
          </InformationContainer>
        </DeleteData>
        <DeleteButtons>
          <Button
            variant="secondary"
            text="Não excluir"
            onClick={() => onShow()}
          />
          <Button
            variant="primary"
            text="Excluir"
            onClick={() => handleDelete()}
          />
        </DeleteButtons>
      </ContentDelete>
    </ContainerDelete>
  );
});

export default DeleteBillet;
