import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${px2vw(30)} ${px2vw(50)};
  gap: ${px2vw(10)};

  width: 100%;
  height: ${px2vw(148)};
  left: ${px2vw(20)};
  top: ${px2vw(20)};

  background: #f8f8f8;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(50)};

  width: ${px2vw(667)};
  height: ${px2vw(88)};
`;

export const InfoDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(50)};

  width: ${px2vw(667)};
  height: ${px2vw(88)};
`;

export const InfoContent = styled.div`
  width: ${px2vw(308.5)};
  height: ${px2vw(88)};
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(20)};

  width: ${px2vw(308.5)};
  height: ${px2vw(88)};
  left: 0px;
  top: 0px;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(10)};

  width: ${px2vw(308.5)};
  height: ${px2vw(88)};
`;

export const TotalText = styled.text`
  width: ${px2vw(204)};
  height: ${px2vw(27)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_tree)};
  line-height: ${px2vw(27)};

  color: rgba(33, 33, 33, 0.5);
`;

export const TotalValue = styled.text`
  width: ${px2vw(209)};
  height: ${px2vw(51)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_one)};
  line-height: ${px2vw(51)};

  color: #212121;
  animation-timing-function: ease-in-out;
`;

export const ButtonDiv = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(30)};
  gap: ${px2vw(10)};

  width: ${px2vw(308.5)};
  height: ${px2vw(88)};

  background: linear-gradient(
      91.03deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #ffcd01;

  border: 3px solid rgba(33, 33, 33, 0.12);

  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: ${px2vw(15)};
`;

export const ButtonText = styled.text`
  width: ${px2vw(196)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_tree)};
  line-height: ${px2vw(27)};

  color: #212121;
`;

export const ContainerSecondary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(30)} ${px2vw(50)};
  gap: ${px2vw(10)};

  width: ${px2vw(767)};
  height: ${px2vw(148)};
  left: ${px2vw(20)};
  top: ${px2vw(249)};

  background: #f8f8f8;

  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
`;

export const ContentSecondary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(50)};

  width: ${px2vw(667)};
  height: ${px2vw(88)};
`;
