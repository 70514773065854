import React, { useEffect, useState } from 'react';

import { Container } from './styles';

import Name from './name';
import Phone from './phone';
import Document from './document';
import usePayment from '../../contexts/PaymentContext';
import { useLocation } from 'react-router-dom';

const Identification: React.FC = () => {
  const { state } = useLocation();

  const [step, setStep] = useState(1);
  const {
    dataPaymentBillet: data,
    setPaymentBilletData: setData,
    clearPaymentBilletData,
  } = usePayment();

  useEffect(() => {
    clearPaymentBilletData();
  }, []);

  const next = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };
  const previous = () => {
    if (step >= 2) {
      setStep(step - 1);
    }
  };

  const steps = [
    {
      id: 1,
      children: <Name key={1} next={next} setData={setData} data={data} />,
    },
    {
      id: 2,
      children: (
        <Phone
          key={2}
          next={next}
          previous={previous}
          setData={setData}
          data={data}
        />
      ),
    },
    {
      id: 3,
      children: (
        <Document
          key={3}
          previous={previous}
          setData={setData}
          data={data}
          came_from={state.type}
        />
      ),
    },
  ];

  return <Container>{steps.map((s) => s.id === step && s.children)}</Container>;
};

export default Identification;
