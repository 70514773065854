import { dinheiroMask } from 'masks-br';
import React from 'react';
import {
  ButtonDiv,
  ButtonText,
  Container,
  ContainerSecondary,
  Content,
  ContentSecondary,
  InfoContent,
  InfoDiv,
  TextContent,
  TextDiv,
  TotalText,
  TotalValue,
} from './styles';

type Props = {
  type?: 'primary' | 'secondary';
  text?: string;
  amount: number;
  onClick?: () => void;
};

export const ButtonWithLength: React.FC<Props> = ({
  type,
  amount,
  onClick,
  text,
}) => {
  switch (type) {
    case 'primary':
      return (
        <Container>
          <Content>
            <InfoDiv>
              <InfoContent>
                <TextDiv>
                  <TextContent>
                    <TotalText>{text}</TotalText>
                    <TotalValue>{dinheiroMask(amount)}</TotalValue>
                  </TextContent>
                </TextDiv>
              </InfoContent>
            </InfoDiv>
            <ButtonDiv onClick={onClick}>
              <ButtonText>Ir para pagamento</ButtonText>
            </ButtonDiv>
          </Content>
        </Container>
      );
    case 'secondary':
      return (
        <ContainerSecondary>
          <ContentSecondary>
            <InfoDiv>
              <InfoContent>
                <TextDiv>
                  <TextContent>
                    <TotalText>{text}</TotalText>
                    <TotalValue>{dinheiroMask(amount)}</TotalValue>
                  </TextContent>
                </TextDiv>
              </InfoContent>
            </InfoDiv>
            <ButtonDiv onClick={onClick}>
              <ButtonText>Ir para pagamento</ButtonText>
            </ButtonDiv>
          </ContentSecondary>
        </ContainerSecondary>
      );
    default:
      return (
        <Container>
          <Content>
            <InfoDiv>
              <InfoContent>
                <TextDiv>
                  <TextContent>
                    <TotalText>{text}</TotalText>
                    <TotalValue>{dinheiroMask(amount)}</TotalValue>
                  </TextContent>
                </TextDiv>
              </InfoContent>
            </InfoDiv>
            <ButtonDiv onClick={onClick}>
              <ButtonText>Ir para pagamento</ButtonText>
            </ButtonDiv>
          </Content>
        </Container>
      );
  }
};
