import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

type Props = {
  authenticated: boolean;
};

const PrivateRoute: React.FC<Props> = ({ authenticated }) => {
  if (!authenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
