import React from 'react';
import { Icons } from '../../assets/Icons';

import {
  Icon,
  Primary,
  Secondary,
  Tertiary,
  Quartenary,
  Image,
} from './styles';

type Props = {
  text?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quartenary';
  style?: React.CSSProperties;
  icon?: keyof typeof Icons;
  image?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const Button: React.FC<Props> = ({
  text,
  variant,
  disabled,
  children,
  onClick,
  icon,
  image,
  style,
}) => {
  switch (variant) {
    case 'primary':
      return (
        <Primary
          onClick={onClick}
          disabled={disabled}
          style={style}
          icon={!!icon}
        >
          {image && <Image src={image} alt="Button Image" />}
          {text || children}
          {icon && <Icon src={Icons[icon]} alt="Button Icon" />}
        </Primary>
      );
    case 'secondary':
      return (
        <Secondary
          onClick={onClick}
          disabled={disabled}
          style={style}
          icon={!!icon}
        >
          {image && <Image src={image} alt="Button Image" />}
          {text || children}
          {icon && <Icon src={Icons[icon]} alt="Button Icon" />}
        </Secondary>
      );
    case 'tertiary':
      return (
        <Tertiary
          onClick={onClick}
          disabled={disabled}
          style={style}
          icon={!!icon}
        >
          {image && <Image src={image} alt="Button Image" />}
          {text || children}
          {icon && <Icon src={Icons[icon]} alt="Button Icon" />}
        </Tertiary>
      );
    case 'quartenary':
      return (
        <Quartenary
          onClick={onClick}
          disabled={disabled}
          style={style}
          icon={!!icon}
        >
          {image && <Image src={image} alt="Button Image" />}
          {text || children}
          {icon && <Icon src={Icons[icon]} alt="Button Icon" />}
        </Quartenary>
      );
    default:
      return (
        <Primary onClick={onClick} disabled style={style} icon={!!icon}>
          {image && <Image src={image} alt="Button Image" />}
          {text || children}
          {icon && <Icon src={Icons[icon]} alt="Button Icon" />}
        </Primary>
      );
  }
};

export default Button;
