import React, { useState, Ref, forwardRef, useImperativeHandle } from 'react';
import { BsDot } from 'react-icons/bs';
import Button from '../../../components/Button';
import {
  Container,
  MainTitle,
  SubTitle,
  TextButton,
  TextButtonBold,
} from './styles';
import { dinheiroMask, cleanMask } from 'masks-br';
import { AiOutlineArrowRight } from 'react-icons/ai';
import paymentCalc, { PaymentCalc } from '../../../utils/paymentCalc';

import { PaymentOptionsCreditRefs } from './OptionsCredit';
import { useNavigate } from 'react-router-dom';
import usePayment, {
  EnumPaymentForm,
  PaymentOptionsRefs,
} from '../../../contexts/PaymentContext';
import parcelamos from '../../../services/parcelamos';
import { useAuth } from '../../../contexts/AuthContext';
import useUtil from '../../../contexts/UtilContext';
import { moneyMask } from '../../../utils/utils';

interface UniqueOptionsProps {
  children?: React.ReactNode;
  moreOptionsCredit?: React.RefObject<PaymentOptionsCreditRefs>;
  value: number;
}

const UniqueOptions = forwardRef(
  (props: UniqueOptionsProps, ref: Ref<PaymentOptionsRefs>) => {
    const navigate = useNavigate();

    const { value, moreOptionsCredit } = props;
    const {
      dataPaymentBillet,
      setPaymentBilletData,
      processPaymentBilletData,
    } = usePayment();

    const { user } = useAuth();
    const { showMessage } = useUtil();

    const [open, setOpen] = useState<boolean | undefined>();

    useImperativeHandle(ref, () => ({
      handleOpen() {
        onShow();
      },
    }));

    function onShow() {
      setOpen(!open);
    }

    function handleMoreOptionsCredit() {
      onShow();
      if (moreOptionsCredit && moreOptionsCredit.current) {
        moreOptionsCredit.current.handleOpen();
      }
    }

    async function handleToPaymentProcessing(
      data: PaymentCalc,
      payment_form: EnumPaymentForm = EnumPaymentForm.CREDIT
    ) {
      const payment_identifier = await handleSaveDataPaymentBillet(data);

      if (
        !payment_identifier ||
        (payment_identifier && !payment_identifier.num_pedido) ||
        !payment_identifier.id_transacao_totem
      ) {
        showMessage({
          type: 'error',
          message: 'Erro ao reconhecer a transação',
        });
        return navigate('/inicial');
      }

      setPaymentBilletData({
        ...dataPaymentBillet,
        installments_number: data.installments_number,
        installments_value: moneyMask(data.installment_amount_interest),
        amount: moneyMask(data.payment_amount),
        payment_identifier: payment_identifier.id_transacao_totem,
        payment_cart_number: payment_identifier.num_pedido,
        search_data: dataPaymentBillet.search_data,
      });

      onShow();

      processPaymentBilletData(
        {
          ...dataPaymentBillet,
          installments_number: data.installments_number,
          installments_value: moneyMask(data.installment_amount_interest),
          amount: moneyMask(data.payment_amount),
          payment_identifier: payment_identifier.id_transacao_totem,
          payment_cart_number: payment_identifier.num_pedido,
        },
        payment_form
      );
      navigate('/inserir-cartao');
    }

    async function handleSaveDataPaymentBillet(data: PaymentCalc) {
      if (!user) {
        showMessage({
          type: 'error',
          message: 'Erro ao reconhecer o estabelecimento',
        });
        return navigate('/inicial');
      }

      const payment_data = await parcelamos.savePaymentData({
        ...dataPaymentBillet,
        phone: cleanMask(dataPaymentBillet.phone),
        document: cleanMask(dataPaymentBillet.document),
        installments_number: data.installments_number,
        installments_value: moneyMask(data.installment_amount_interest),
        amount: moneyMask(data.payment_amount),
        id_usuario: user.usuario.id_usuario,
        id_estabelecimento: user.usuario.id_estabelecimento,
      });
      return payment_data;
    }

    function cancelPayment() {
      setOpen(false);
    }

    return (
      <Container open={open}>
        <MainTitle>Selecione como quer pagar</MainTitle>
        <Button
          disabled={
            paymentCalc({
              bankslip_value: value,
              installments_number: 1,
              payment_form: EnumPaymentForm.DEBIT,
            }).payment_amount < 5
          }
          variant="primary"
          onClick={() =>
            handleToPaymentProcessing(
              paymentCalc({
                bankslip_value: value,
                installments_number: 1,
                payment_form: EnumPaymentForm.DEBIT,
              }),
              EnumPaymentForm.DEBIT
            )
          }
        >
          <TextButtonBold>Débito</TextButtonBold>
          <BsDot />
          <TextButton>
            {dinheiroMask(
              paymentCalc({
                bankslip_value: value,
                installments_number: 1,
                payment_form: EnumPaymentForm.DEBIT,
              }).payment_amount
            )}
          </TextButton>
          <AiOutlineArrowRight />
        </Button>
        <Button
          disabled={
            paymentCalc({
              bankslip_value: value,
              installments_number: 1,
              payment_form: EnumPaymentForm.CREDIT,
            }).payment_amount < 5
          }
          variant="primary"
          onClick={() =>
            handleToPaymentProcessing({
              ...paymentCalc({
                bankslip_value: value,
                installments_number: 1,
                payment_form: EnumPaymentForm.CREDIT,
              }),
            })
          }
        >
          <TextButtonBold>Crédito</TextButtonBold>
          <BsDot />
          <TextButton>À vista</TextButton>
          <BsDot />
          <TextButton>
            {dinheiroMask(
              paymentCalc({
                bankslip_value: value,
                installments_number: 1,
                payment_form: EnumPaymentForm.CREDIT,
              }).payment_amount
            )}
          </TextButton>
          <AiOutlineArrowRight />
        </Button>
        <SubTitle>Sugestões de parcelamento</SubTitle>

        <Button
          disabled={
            paymentCalc({
              bankslip_value: value,
              installments_number: 12,
              payment_form: EnumPaymentForm.CREDIT,
            }).installment_amount_interest < 5
          }
          variant="primary"
          onClick={() =>
            handleToPaymentProcessing(
              paymentCalc({
                bankslip_value: value,
                installments_number: 12,
                payment_form: EnumPaymentForm.CREDIT,
              })
            )
          }
        >
          <TextButtonBold>Crédito</TextButtonBold>
          <BsDot />
          <TextButton>
            12x de {''}
            {dinheiroMask(
              paymentCalc({
                bankslip_value: value,
                installments_number: 12,
                payment_form: EnumPaymentForm.CREDIT,
              }).installment_amount_interest
            )}
          </TextButton>
          <AiOutlineArrowRight />
        </Button>
        <Button
          disabled={
            paymentCalc({
              bankslip_value: value,
              installments_number: 6,
              payment_form: EnumPaymentForm.CREDIT,
            }).installment_amount_interest < 5
          }
          variant="primary"
          onClick={() =>
            handleToPaymentProcessing(
              paymentCalc({
                bankslip_value: value,
                installments_number: 6,
                payment_form: EnumPaymentForm.CREDIT,
              })
            )
          }
        >
          <TextButtonBold>Crédito</TextButtonBold>
          <BsDot />
          <TextButton>
            6x de{' '}
            {dinheiroMask(
              paymentCalc({
                bankslip_value: value,
                installments_number: 6,
                payment_form: EnumPaymentForm.CREDIT,
              }).installment_amount_interest
            )}
          </TextButton>
          <AiOutlineArrowRight />
        </Button>
        <Button
          disabled={
            paymentCalc({
              bankslip_value: value,
              installments_number: 4,
              payment_form: EnumPaymentForm.CREDIT,
            }).installment_amount_interest < 5
          }
          variant="primary"
          onClick={() =>
            handleToPaymentProcessing(
              paymentCalc({
                bankslip_value: value,
                installments_number: 4,
                payment_form: EnumPaymentForm.CREDIT,
              })
            )
          }
        >
          <TextButtonBold>Crédito</TextButtonBold>
          <BsDot />
          <TextButton>
            4x de{' '}
            {dinheiroMask(
              paymentCalc({
                bankslip_value: value,
                installments_number: 4,
                payment_form: EnumPaymentForm.CREDIT,
              }).installment_amount_interest
            )}
          </TextButton>
          <AiOutlineArrowRight />
        </Button>
        <Button variant="primary" onClick={() => handleMoreOptionsCredit()}>
          <TextButtonBold>Ver mais opções</TextButtonBold>
        </Button>
        <Button variant="secondary" onClick={cancelPayment}>
          <TextButtonBold>Cancelar</TextButtonBold>
        </Button>
      </Container>
    );
  }
);

export default UniqueOptions;
