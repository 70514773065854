import styled, { keyframes } from 'styled-components';
import { Images } from '../../../assets/Icons';
import px2vw from '../../../utils/pc2vw';
import pc2vw from '../../../utils/pc2vw';

const AnimeArrow = keyframes`
0%{
  transform: translateY(-100px);
  opacity: 0.5;
}
100%{
  transform: translateY(100px);
  opacity: 1;
}
`;

export const TitlePrinterInfo = styled.h1`
  font-weight: 200;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_one)};
  text-align: center;
  width: ${pc2vw(330)};
  z-index: 1;
  @media print {
    display: none;
  }
`;

export const SubTitlePrinterInfo = styled.h1`
  font-weight: 200;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_tree)};
  text-align: center;
  width: ${pc2vw(330)};
  z-index: 1;
  @media print {
    display: none;
  }
`;

export const ContainerPrinter = styled.div`
  width: ${px2vw(768)};
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${pc2vw(40)};
  overflow: hidden;
`;

export const ArrowImage = styled.img.attrs({
  alt: 'ArrowPrinter',
  src: Images.arrow_print,
})`
  animation: ${AnimeArrow} 0.5s ease-in-out alternate infinite;
  @media print {
    display: none;
  }
`;
