import React, { useEffect, useState } from 'react';
import {
  Container,
  InfoButton,
  BankslipPaymentInfo,
  ButtonsContainer,
  TitleInfo,
  SubTextPrimary,
  SubTextSecondary,
} from './styles';
import Button from '../../components/Button';
import { BsDot, BsPrinter } from 'react-icons/bs';
import { AiOutlineArrowRight, AiOutlineMail } from 'react-icons/ai';
import { MdOutlineTextsms } from 'react-icons/md';
import { HiPlus } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import CheckCircleImage from '../../assets/images/svg/check-circle.svg';

const Receipt: React.FC = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => setCount(count - 1), 1000);
    if (count === 0) {
      navigate('/comprovante-impressao');
    }
    return () => clearInterval(interval);
  }, [count]);

  return (
    <Container>
      <BankslipPaymentInfo>
        <img src={CheckCircleImage} alt="Check Circle Image" />
        <TitleInfo>Boleto pago!</TitleInfo>
        <SubTextPrimary>
          Escolha como quer ter acesso ao seu comprovante de pagamento.
        </SubTextPrimary>
        <SubTextSecondary>
          Finalizando em <strong>{count} segundos</strong>
        </SubTextSecondary>
      </BankslipPaymentInfo>

      <ButtonsContainer>
        <Button
          variant="primary"
          onClick={() => navigate('/comprovante-impressao')}
        >
          <p>Comprovante</p>
          <BsDot />
          <InfoButton>
            <BsPrinter />
            <p>Imprimir</p>
          </InfoButton>
          <AiOutlineArrowRight />
        </Button>
        <Button
          variant="primary"
          onClick={() => navigate('/comprovante-email')}
        >
          <p>Comprovante</p>
          <BsDot />
          <InfoButton>
            <BsPrinter />
            <p>Imprimir</p>
          </InfoButton>
          <HiPlus />
          <InfoButton>
            <AiOutlineMail />
            <p>E-mail</p>
          </InfoButton>
          <AiOutlineArrowRight />
        </Button>
        <Button variant="primary" onClick={() => navigate('/comprovante-sms')}>
          <p>Comprovante</p>
          <BsDot />
          <InfoButton>
            <BsPrinter />
            <p>Imprimir</p>
          </InfoButton>
          <HiPlus />
          <InfoButton>
            <MdOutlineTextsms />
            <p>SMS</p>
          </InfoButton>
          <AiOutlineArrowRight />
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default Receipt;
