import React, { useEffect, useRef, useState } from 'react';

import 'react-simple-keyboard/build/css/index.css';
import Phases from '../../components/Phases';

import {
  Container,
  Content,
  StepBack,
  TextHead,
  ButtonPayment,
  StepBackText,
  StepBackIcon,
  MessageContainerBankslip,
  FragmentDiv,
} from './styles';
import { BankslipItem } from '../../components/BankslipItem';
import { ButtonWithLength } from '../../components/ButtonWithLength';
import { Icons } from '../../assets/Icons';
import ActionButton from '../../components/ActionButton';
import { ScreenTypes } from '.';
import UniqueOptions from '../Payment/Unique/Options';
import UniqueOptionsCredit from '../Payment/Unique/OptionsCredit';
import usePayment, { PaymentOptionsRefs } from '../../contexts/PaymentContext';

type Props = {
  goToScreen: (name: ScreenTypes) => void;
  removeBillet: (index: number) => void;
  goBack: () => void;
};

const Bankslips: React.FC<Props> = ({ goToScreen, removeBillet, goBack }) => {
  const [sum, setSum] = useState(0);
  const { dataPaymentBillet } = usePayment();

  const unicPaymentRef = useRef<PaymentOptionsRefs>(null);
  const unicOptionsCredRef = useRef<PaymentOptionsRefs>(null);

  useEffect(() => {
    if (dataPaymentBillet.billets.length) {
      const sum = dataPaymentBillet.billets.reduce(
        (value, current) => value + current.valor_boleto,
        0
      );
      setSum(sum);
    } else {
      setSum(0);
      goToScreen(ScreenTypes.Leitor);
    }
  }, [dataPaymentBillet]);

  function openUnicPayment() {
    unicPaymentRef.current && unicPaymentRef.current.handleOpen();
  }

  function goBackOptionsCredit() {
    unicOptionsCredRef.current && unicOptionsCredRef.current.handleOpen();
    unicPaymentRef.current && unicPaymentRef.current.handleOpen();
  }

  return (
    <Container>
      <Content>
        <Phases
          icons={[
            Icons.lock_white,
            Icons.pencil,
            Icons.lock_white,
            Icons.whiteCheck,
          ]}
          texts={['Identificação', 'Dados do boleto', 'Pagamento', '']}
          step={2}
        />
        <MessageContainerBankslip>
          <TextHead>Seus boletos</TextHead>
          {dataPaymentBillet.billets.map((billet, index) => (
            <BankslipItem
              key={index}
              billet={billet}
              removeBillet={() => removeBillet(index)}
            />
          ))}

          <ActionButton
            color="primary"
            text="Adicionar Boleto"
            icon="feather"
            onClick={() => goToScreen(ScreenTypes.Leitor)}
          />
          <StepBack onClick={goBack}>
            <StepBackIcon src={Icons.chevron_left} />
            <StepBackText>Voltar</StepBackText>
          </StepBack>
        </MessageContainerBankslip>
        <FragmentDiv />
      </Content>
      <ButtonPayment>
        <ButtonWithLength
          onClick={openUnicPayment}
          text={`Total de (${dataPaymentBillet.billets.length}) boletos`}
          amount={sum}
        />
      </ButtonPayment>
      <UniqueOptions
        value={sum}
        ref={unicPaymentRef}
        moreOptionsCredit={unicOptionsCredRef}
      />
      <UniqueOptionsCredit
        value={sum}
        ref={unicOptionsCredRef}
        goBack={goBackOptionsCredit}
      />
    </Container>
  );
};

export default Bankslips;
