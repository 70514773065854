import React from 'react';
import ReceiptGenerated from '../Generated';
import {
  ContainerPrinter,
  SubTitlePrinterInfo,
  TitlePrinterInfo,
  ArrowImage,
} from './styles';

const ReceiptPrinter: React.FC = () => {
  return (
    <ContainerPrinter>
      <TitlePrinterInfo>Retire seu comprovante na impressora</TitlePrinterInfo>
      <SubTitlePrinterInfo>
        Aguarde o término da impressão para retirar seu comprovante.
      </SubTitlePrinterInfo>
      <ArrowImage />
      <ReceiptGenerated />
    </ContainerPrinter>
  );
};

export default ReceiptPrinter;
