import React, { useState } from 'react';

import { Container } from './styles';

import AgreementsType from './agreementsType';
import FetchDebits from './fetchDebits';
import Bankslips from './debits';
import parcelamos from '../../services/parcelamos';
import {
  BilletResponseType,
  Debits as DebitsType,
  EnergyCityHallDebitsResponse,
  EnumTypePartner,
  GetAllDebitsRequest,
  GetPlateDebitsResponse,
  PlateDebitsDetails,
} from '../../services/parcelamos/types';
import useUtil from '../../contexts/UtilContext';
import usePayment, { PaymentBilletType } from '../../contexts/PaymentContext';
import Debits from './debits';
import { useAuth } from '../../contexts/AuthContext';

export enum ScreenTypes {
  AgreementsType = 1,
  FetchDebits = 2,
  Debits = 3,
}

export type GetDebitsRequest = {
  [key: string]: string;
};

export type BilletType = BilletResponseType;

const Agreements: React.FC = () => {
  const { user } = useAuth();
  const { showMessage, loadingScreen } = useUtil();
  const { dataPaymentBillet, setPaymentBilletData } = usePayment();

  const [step, setStep] = useState(1);

  const goToScreen = (name: ScreenTypes) => {
    setStep(name);
  };

  const goBack = () => {
    setStep(1);
  };

  function getFieldsByIdPartner() {
    if (!user) {
      return [];
    }
    return user.partner_fields.filter(
      (field) => field.id_partner === dataPaymentBillet.id_partner
    );
  }

  const getDebitsData = async (data: GetDebitsRequest) => {
    const fields = getFieldsByIdPartner();

    let message = 'Campo(s) obrigatório(s):';

    fields.forEach((f) => {
      if (f.required) {
        if (!data[f.input_key]) {
          message += ' ' + f.label + ', ';
        }
      }
    });

    if (message.split(':')[1]) {
      return showMessage({
        type: 'error',
        message,
      });
    }
    if (!dataPaymentBillet.id_partner || !dataPaymentBillet.id_type_partner) {
      return showMessage({
        type: 'error',
        message: 'Não foi possível consultar seus débitos',
      });
    }

    loadingScreen(true);
    const request: GetAllDebitsRequest = {
      ...data,
      id_partner: dataPaymentBillet.id_partner,
      type_partner: dataPaymentBillet.id_type_partner,
    };

    const response = await parcelamos.getAllTypeDebits(request);
    if (response.error || response.message) {
      loadingScreen(false);
      showMessage({ type: 'error', message: response.message! });
      return;
    }

    setPaymentBilletData({
      search_data: request,
      ...dataPaymentBillet,
      debits: [
        ...dataPaymentBillet.debits,
        ...response.debitos.map((item) =>
          Object.assign(item, {
            ano_fabricacao: response.ano_fabricacao,
            marca: response.marca,
            cor: response.cor,
          } as PlateDebitsDetails & DebitsType & EnergyCityHallDebitsResponse)
        ),
      ],
    });
    setStep(ScreenTypes.Debits);
    loadingScreen(false);
  };

  const removeBillet = (index: number) => {
    const billets = dataPaymentBillet.billets;
    const arr = billets.filter((_, idx) => idx !== index);
    setPaymentBilletData({ ...dataPaymentBillet, billets: arr });
  };

  const steps = [
    {
      id: 1,
      children: <AgreementsType key={1} goToScreen={goToScreen} />,
    },
    {
      id: 2,
      children: (
        <FetchDebits
          key={2}
          goToScreen={goToScreen}
          fetchDebits={getDebitsData}
          goBack={goBack}
        />
      ),
    },
    {
      id: 3,
      children: (
        <Debits
          key={3}
          goToScreen={goToScreen}
          removeBillet={removeBillet}
          goBack={goBack}
        />
      ),
    },
  ];

  return <Container>{steps.map((s) => s.id === step && s.children)}</Container>;
};

export default Agreements;
