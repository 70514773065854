import styled, { keyframes } from 'styled-components';
import px2vw from '../../../utils/pc2vw';
import pc2vw from '../../../utils/pc2vw';
import CardMachine from '../../../assets/images/svg/card-machine.svg';
import ArrowRight from '../../../assets/images/png/arrow-right-short-adj.png';

const leftAndRight = keyframes`
0% {
  
  transform: translate(150px);
}
100% {
  
  transform: translate(-50px, 0);
}

`;

export const Container = styled.div`
  width: ${px2vw(768)};
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

export const MainTitle = styled.h1`
  margin-top: 6vh;
  font-weight: 200;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_nine)};
`;

export const SubMainTitle = styled.div`
  margin: 2vh 0;
  h2 {
    font-weight: 300;
    font-size: ${({ theme }) => px2vw(theme.font_size.level_ten)};
    opacity: 0.5;
  }
  img {
    width: ${px2vw(150)};
    margin: ${px2vw(-60)} 0;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: ${px2vw(508)};
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: ${px2vw(508)};

  p {
    font-weight: 100;
  }
`;

export const CardMachineCenter = styled.div`
  width: 100%;
  height: ${px2vw(500)};
  background-image: url(${CardMachine});
  background-position: center, bottom right;
  background-repeat: no-repeat;
`;

export const MachineArrowRight = styled.div`
  width: 100%;
  height: ${px2vw(500)};
  background-image: url(${ArrowRight});
  background-position: center, bottom right;
  background-repeat: no-repeat;

  animation: ${leftAndRight} 0.5s ease-in-out alternate infinite;
`;

export const StepBack = styled.span`
  width: ${px2vw(90)};
  height: ${px2vw(27)};
  margin: ${px2vw(6)} 0;
  display: flex;
  align-items: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font_size.level_tree};
  line-height: ${px2vw(27)};

  color: ${({ theme }) => theme.colors.dark_mid};
`;

export const TitleInfo = styled.h1`
  font-weight: 300;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_eight)};
  text-align: center;
  width: ${pc2vw(330)};
`;

export const SubTitleInfo = styled.h2`
  font-weight: 200;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_two)};
  text-align: center;
  width: ${pc2vw(330)};
`;

export const ContainerProcessing = styled.div`
  width: ${px2vw(768)};
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${px2vw(40)};
  margin-top: ${px2vw(30)};

  img {
    width: ${px2vw(300)};
  }
`;

export const MainTitleInfo = styled.h1`
  font-weight: 700;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_ten)};
  color: #ff0000;
`;

export const ContainerErrorPayment = styled.div`
  width: ${px2vw(768)};
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  width: ${px2vw(768)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${px2vw(14)};
`;

export const BankslipPaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${px2vw(10)};
  margin: ${px2vw(300)} 0 ${px2vw(200)} 0;
  text-align: center;
  width: ${pc2vw(480)};

  font-style: normal;
`;

export const SubTextPrimary = styled.p`
  margin: ${px2vw(2)} 0;
  font-weight: 200;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_six)};
`;

export const SubTextSecondary = styled.span`
  font-weight: 300;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_four)};
`;
