import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.primary};
  align-items: center;
  justify-content: space-between;
  padding: ${px2vw(100)} 0px;
  gap: ${px2vw(48)};
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: ${px2vw(508)};
  flex-direction: column;
  align-items: center;
  padding: 0px ${px2vw(100)};
  gap: ${px2vw(48)};
`;

export const Description = styled.img`
  width: ${px2vw(300)};
  height: ${px2vw(74)};

  gap: ${px2vw(10)};
  background-size: 100%;
`;

export const Text = styled.span`
  width: 100%;
  height: ${px2vw(58)};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-size: ${px2vw(48)};
  line-height: ${px2vw(58)};
  text-align: center;

  color: ${({ theme }) => theme.colors.dark_full};
`;
