import React from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { ClassElement } from 'typescript';
import { layout } from '../../assets/keyboard';

import { Container, KeyboardView } from './styles';

type Props = {
  onChange?: ((input: string, e?: MouseEvent | undefined) => any) | undefined;
  onKeyPress?: () => void;
  classNameContainer?: string;
  maxLength?: number;
  inputName?: string;
};

export const NumericKeyboard: React.FC<Props> = (props) => {
  const { classNameContainer } = props;
  return (
    <Container className={classNameContainer}>
      <KeyboardView>
        <Keyboard
          useMouseEvents={false}
          disableCaretPositioning
          {...props}
          layoutName="number"
          layout={layout}
          theme={'hg-theme-default hg-layout-default myTheme'}
          display={{
            '{bksp}': '⌫',
          }}
          buttonTheme={[
            {
              class: 'hg-black',
              buttons: '1 2 3 4 5 6 7 8 9  0 {bksp}',
            },
          ]}
        />
      </KeyboardView>
    </Container>
  );
};
