import styled from 'styled-components';
import { Images } from '../../../assets/Icons';
import px2vw from '../../../utils/pc2vw';

export const ContainerReceiptGenerated = styled.div`
  width: 90%;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${px2vw(22)};
  overflow: hidden;
  @media screen {
    display: none;
  }
`;

export const HeaderView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ContainerReceiptCarrierText = styled.span`
  background: #ffffff;
  text-align: center;
  font-weight: 300;
  font-size: ${px2vw(28)};
`;

export const Title = styled.span`
  text-align: center;
  font-size: ${px2vw(48)};
  font-weight: 700;
`;

export const SubTitle = styled.span`
  text-align: center;
  font-size: ${px2vw(28)};
  font-weight: 700;
`;

export const Informations = styled.span`
  text-align: center;
  font-size: ${px2vw(26)};
`;

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${px2vw(5)};
`;

export const Logo = styled.img.attrs({
  src: Images.logo,
  alt: 'logo_print',
})`
  width: ${px2vw(300)};
  height: ${px2vw(200)};
  margin-top: ${px2vw(50)};
  -webkit-print-color-adjust: exact;
`;

export const Divider = styled.hr`
  border: none;
  border-top: 2px dashed #000000;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 90%;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableTr = styled.tr`
  width: 100%;
`;

export const TableTd = styled.td`
  width: ${(props) => props.width};
  text-align: ${(props) => props.align};
  font-size: ${px2vw(26)};
  word-break: break-all;
  padding: 5px 0;
`;

export const TableTh = styled.th`
  text-align: center;
  font-size: ${px2vw(26)};
`;

export const FootTotal = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: right;
  font-size: ${px2vw(26)};
  font-weight: 700;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${px2vw(20)};
  width: 60%;

  p {
    font-size: ${px2vw(24)};
    text-align: center;
  }
`;

export const ContainerPayer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  justify-content: center;
  align-items: center;
`;

export const PayerInformations = styled.span`
  text-align: center;
  font-size: ${px2vw(26)};
  padding: 5px 0;
`;
