import styled, { css } from 'styled-components';
import pc2vw from '../../utils/pc2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 10px;
  color: #000000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`;

export const InputText = styled.input<{ error: boolean; rtl?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 25px 60px;
  gap: 10px;
  border: none;
  width: ${pc2vw(648)};
  height: ${pc2vw(87)};
  background: ${({ theme }) => theme.colors.input_default};
  border-radius: ${pc2vw(15)};
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_two)};
  line-height: 27px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  ::placeholder {
    font-weight: 400;
    font-size: ${({ theme }) => pc2vw(theme.font_size.level_tree)};
  }
  :focus {
    outline: none;
  }

  ${({ rtl }) => {
    if (rtl) {
      return css`
        direction: rtl;
      `;
    }
  }}

  ${({ error }) =>
    error
      ? `
    color: #FF0000;
    border: 5px solid rgba(255, 0, 0, 0.3);
  `
      : `
  color: black;
  border:none;
  `}
`;
