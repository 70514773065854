import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';

export const Logo = styled.img.attrs(({ theme }) => ({
  src: theme.logo,
  alt: 'logo',
}))`
  width: ${px2vw(559)};
  /* height: ${px2vw(132.39)}; */
  object-fit: contain;
`;
