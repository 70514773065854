import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../assets/Icons";
import CardInfo from "../../components/CardInfo";
import Logo from "../../components/Logo";
import usePayment from "../../contexts/PaymentContext";
import { Container, Content, Description, Text } from "./styles";
import { useAuth } from "../../contexts/AuthContext";

const Home: React.FC = () => {
  const navigate = useNavigate();

  const { clearPaymentBilletData } = usePayment();
  const { hasPartner } = useAuth();

  useEffect(() => {
    clearPaymentBilletData();
  }, []);

  return (
    <Container>
      <Description src={Icons.totem_sensibility} />
      <Content>
        <Text>O que você quer fazer?</Text>
        <CardInfo
          primary_text="Pagar boletos"
          secondary_text="Pague um ou mais boletos de uma só vez."
          icon="barcode_scan"
          color="dark_mid"
          onClick={() =>
            navigate("/identificacao", { state: { type: "Billing" } })
          }
        />

        <CardInfo
          primary_text="Outros pagamentos"
          secondary_text="Convênio com Detran, Prefeituras e Energia."
          icon="note"
          color="dark_mid"
          disabled={!hasPartner()}
          onClick={() =>
            navigate("/identificacao", { state: { type: "Agreements" } })
          }
        />
      </Content>
      <Logo />
    </Container>
  );
};
export default Home;
