import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';

export const Container = styled.div`
  width: ${px2vw(768)};
  height: 100%;
  background: #000000;
  overflow: hidden;
`;

export const LogoView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(200)};

  width: 100%;
  margin-top: 74.3px;
`;

export const Text = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-size: ${px2vw(58)};
  line-height: ${px2vw(70)};
  text-align: center;

  color: ${({ theme }) => theme.colors.white_full};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(200)};

  width: ${px2vw(769)};
  height: 100%; //replace;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px ${px2vw(60)};
  gap: ${px2vw(50)};

  width: ${px2vw(769)};
  height: ${px2vw(218)};
`;

export const Row = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${px2vw(24)};

  width: ${px2vw(769)};
  height: ${px2vw(318)};
`;

export const KeyboardView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(20)};

  width: ${px2vw(769)};
  height: 100%;
  .simple-keyboard.hg-theme-default.myTheme {
    border-radius: 10px;
    margin: 10px;
    width: calc(100% - 20px);
    background-color: #000000;
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-black {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${px2vw(10)};
    gap: ${px2vw(10)};

    width: ${px2vw(58.5)};
    height: ${px2vw(57.4)};

    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: ${px2vw(36)};
    line-height: ${px2vw(36)};
    text-align: center;

    color: #ffffff;
  }
`;

export const LoadingMessageComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${px2vw(20)};
  margin-top: -50px;

  width: ${px2vw(769)};
`;

export const ReadyTextHeader = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-size: ${px2vw(45)};
  line-height: 58px;
  text-align: center;

  color: ${({ theme }) => theme.colors.white_full};
`;

export const ReadyTextBody = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-size: ${px2vw(27)};
  text-align: center;

  color: ${({ theme }) => theme.colors.white_full};
`;
