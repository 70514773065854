import React, { useEffect, useRef, useState } from "react";

import Phases from "../../components/Phases";
import TwoArrows from "../../assets/images/svg/two-arrows.svg";

import {
  Container,
  Content,
  StepBack,
  TextBody,
  TextHead,
  ArrowsImage,
  StepBackIcon,
  StepBackText,
  MessageContainerRead,
  InputAbsolute,
} from "./styles";
import { Icons } from "../../assets/Icons";
import { ScreenTypes } from ".";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../components/ActionButton";

type Props = {
  goToScreen: (name: ScreenTypes) => void;
  fetchBillet: (billet_code: string) => Promise<void>;
};
const ReadBankslip: React.FC<Props> = ({ goToScreen, fetchBillet }) => {
  const navigate = useNavigate();
  const [digitable, setDigitable] = useState("");
  const inputRef = useRef<any>(null);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      runFetchBillet();
    }
  };

  async function runFetchBillet() {
    await fetchBillet(digitable);
    setDigitable("");
  }

  function focus() {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  return (
    <Container onClick={focus}>
      <Content>
        <Phases
          icons={[Icons.lock, Icons.pencil, Icons.lock, Icons.whiteCheck]}
          texts={["Identificação", "Dados do boleto", "Pagamento", ""]}
          step={2}
        />
        <MessageContainerRead>
          <TextHead>Escaneie o boleto</TextHead>
          <TextBody>
            Aponte o código de barras do <br />
            boleto, para o leitor abaixo.
          </TextBody>
          <ActionButton
            color="primary"
            text="Digitar linha digitável"
            icon="keyboard_icon"
            onClick={() => goToScreen(ScreenTypes.Digitavel)}
          />
        </MessageContainerRead>

        <ArrowsImage src={TwoArrows} />

        <StepBack className="padding-100" onClick={() => navigate("/inicial")}>
          <StepBackIcon src={Icons.chevron_left} />
          <StepBackText>Voltar</StepBackText>
        </StepBack>
        <InputAbsolute
          value={digitable}
          onKeyPress={handleKeyPress}
          onChange={(e) => setDigitable(e.target.value)}
          ref={inputRef}
          autoFocus
        />
      </Content>
    </Container>
  );
};

export default ReadBankslip;
