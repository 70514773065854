import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import parcelamos from "../../../services/parcelamos";
import { ContainerSms, QuestionTitle } from "./styles";
import { v4 as uuidv4 } from "uuid";
import usePayment from "../../../contexts/PaymentContext";
import useUtil from "../../../contexts/UtilContext";
import { celularMask, cleanMask } from "masks-br";
import { NumericKeyboard } from "../../../components/NumericKeyboard";
import { KeyboardView } from "../../Identification/styles";

const ReceiptSms: React.FC = () => {
  const navigate = useNavigate();
  const [celularNumber, setCelularNumber] = useState<string | undefined>();
  const { dataPaymentBillet } = usePayment();
  const { showMessage } = useUtil();

  useEffect(() => {
    if (dataPaymentBillet.phone) {
      setCelularNumber(celularMask(dataPaymentBillet.phone));
    }
  }, []);

  async function handleSendComprovanteSms() {
    try {
      await parcelamos.notification({
        send_to_sms: true,
        telefone: celularNumber ? cleanMask(celularNumber) : undefined,
        send_to_email: false,
        nome: dataPaymentBillet.name,
        boletos: dataPaymentBillet.billets.map((billet) => {
          return {
            cod_boleto: billet.codigo_barras,
            valor: billet.valor_boleto.toString(),
            liquidado_em: billet.data_pagamento
              ? new Date(billet.data_pagamento)
              : undefined,
            liquidacao_id: dataPaymentBillet.payment_identifier || uuidv4(),
          };
        }),
      });
      return true;
    } catch (err) {
      showMessage({
        type: "error",
        message: "Erro ao enviar o comprovante por e-mail",
      });
      return false;
    }
  }

  function handleSendComprovanteSmsContinue() {
    if (!handleSendComprovanteSms()) navigate("/comprovante");
    navigate("/comprovante-sucesso");
  }
  return (
    <ContainerSms>
      <QuestionTitle>Qual celular enviamos o comprovante?</QuestionTitle>
      <Input
        name="phone"
        type="phone"
        value={celularNumber && celularNumber}
        onChange={(phone) => setCelularNumber(celularMask(phone))}
        placeholder="Celular com DDD"
      />
      <Button
        text="Continuar"
        variant="primary"
        onClick={() => handleSendComprovanteSmsContinue()}
      />

      <KeyboardView>
        <NumericKeyboard
          maxLength={11}
          onChange={(e) => setCelularNumber(celularMask(e))}
        />
      </KeyboardView>
    </ContainerSms>
  );
};

export default ReceiptSms;
