import React, { useState } from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Logo from '../../components/Logo';

import Keyboard from 'react-simple-keyboard';

import {
  Container,
  KeyboardView,
  Content,
  Text,
  Form,
  LogoView,
  Row,
} from './styles';
import { layout } from '../../assets/keyboard';

type Props = {
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  signIn: () => void;
  password: string;
};

const Password: React.FC<Props> = ({ setPassword, signIn, password }) => {
  const [shift, setShift] = useState(false);

  function onKeyPress(key: string) {
    if (key === '{shift}') {
      setShift(!shift);
    }
  }

  return (
    <Container>
      <Content>
        <LogoView>
          <Logo />
        </LogoView>
        <Form>
          <Text>Senha</Text>
          <Row>
            <Input
              placeholder="Senha"
              name="password"
              type="password"
              value={password}
              onChange={setPassword}
              required
            />
            <Button text="Continuar" variant="primary" onClick={signIn} />
          </Row>
        </Form>
        <KeyboardView>
          <Keyboard
            onKeyPress={onKeyPress}
            onChange={(e) => setPassword(e)}
            layoutName={
              shift ? 'default_with_number_shift' : 'default_with_number'
            }
            layout={layout}
            theme={'hg-theme-default hg-layout-default myTheme'}
            display={{
              '{bksp}': '⌫',
              '{space}': '␣',
              '{shift}': '⇧',
            }}
            buttonTheme={[
              {
                class: 'hg-black',

                buttons:
                  "' " +
                  '` 1 2 3 4 5 6 7 8 9 0 - = {bksp} q w e r t y u i o p [ ] \\ a s d f g h j k l ;  {shift} z x c v b n m , . / .com @ {space} ~ ! @ # $ % ^ &amp; * ( ) _ + Q W E R T Y U I O P { } | A S D F G H J K L Ç : " Z X C V B N M &lt; &gt; ?',
              },
            ]}
          />
        </KeyboardView>
      </Content>
    </Container>
  );
};

export default Password;
