import { Images } from '../assets/Icons';
import Whitelabel from '../services/parcelamos/types';

export type ThemeType = typeof theme;

let white_label = null;
const white_label_storage = sessionStorage.getItem('@parcelamos/sign-in');
if (white_label_storage) {
  white_label = JSON.parse(white_label_storage).whitelabel as Whitelabel;
}

export const theme = {
  colors: {
    primary: white_label ? white_label.primary_color : '#FFCD01',
    secondary: white_label ? white_label.secondary_color : '#323031',
    input_default: '#F8F8F8',
    dark_full: '#212121',
    dark_mid: 'rgba(33, 33, 33, 0.5)',
    dark_low: 'rgba(33, 33, 33, 0.35)',
    dark_super_low: 'rgba(33, 33, 33, 0.25)',
    dark_outlined: 'rgba(33, 33, 33, 0.12)',
    dark_starter: 'rgba(33, 33, 33, 0.05)',
    white_full: '#FFFFFF',
    white_mid: 'rgba(255, 255, 255, 0.5)',
    white_disabled: 'rgba(255, 255, 255, 0.25)',
    red_venetian: '#C80815',
    red_fire_brick: '#B22222',
    green_emerald: '#50C878',
    green_jungle: '#29AB87',
  },
  font_size: {
    level_one: 42,
    level_ten: 38,
    level_eight: 48,
    level_nine: 58,
    level_two: 30,
    level_tree: 22,
    level_four: 18,
    level_five: 32,
    level_six: 24,
    level_seven: 20,
  },
  box_shadow: {
    level_one: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    level_two: '0px 0px 25px rgba(0, 0, 0, 0.14)',
    level_tree: '0px 0px 25px rgba(0, 0, 0, 0.25)',
    level_four: '0px 0px 40px rgba(0, 0, 0, 0.25)',
  },
  logo: white_label ? white_label.main_logo_url : Images.logo,
};
