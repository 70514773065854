import axios from 'axios';
import reCaptcha from '../reCaptcha';
import {
  BilletRequestType,
  BilletResponseType,
  EnergyCityHallDebitsResponse,
  EnumTypePartner,
  GetAllDebitsRequest,
  GetPlateDebitsResponse,
  HandleError,
  INotification,
  LoginRequestType,
  SavePaymentRequest,
  SavePaymentResponse,
  User,
  UserTotem,
} from './types';

import {
  IdentificationType,
  PaymentBilletType,
} from '../../contexts/PaymentContext';

class ParcelamosService {
  private api = axios.create({
    baseURL: process.env.REACT_APP_PARCELAMOS_API_URL,
    headers: {
      Authorization:
        'Bearer ' +
        process.env.REACT_APP_PARCELAMOS_API_CLIENT_ID +
        '|' +
        process.env.REACT_APP_PARCELAMOS_API_CLIENT_SECRET,
    },
  });

  async login({
    document,
    password,
  }: LoginRequestType): Promise<User & HandleError> {
    try {
      const response = await this.api.post('/totem/login', {
        documento: document,
        senha: password,
      });

      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }

  async getBillet({
    billet_code,
  }: BilletRequestType): Promise<BilletResponseType & HandleError> {
    try {
      const captcha = await reCaptcha.getRecaptchaToken('GET_BILLET');

      const response = await this.api.post(`/portal/boleto/${billet_code}`, {
        token: captcha,
      });

      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }

  async saveUserData({
    name,
    document,
    phone,
  }: IdentificationType): Promise<UserTotem> {
    try {
      const response = await this.api.post('/totem/user', {
        cpf: document,
        full_name: name,
        phone: phone,
      });

      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }

  async savePaymentData(
    data: SavePaymentRequest
  ): Promise<SavePaymentResponse> {
    try {
      const response = await this.api.post('/totem/payment', data);

      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }

  async notification(data: INotification) {
    try {
      const response = await this.api.post('/totem/notifications', data);

      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }

  async getAllTypeDebits({
    id_partner,
    license_plate,
    renavam,
    type_partner,
    cod_busca,
    document,
  }: GetAllDebitsRequest): Promise<
    GetPlateDebitsResponse & EnergyCityHallDebitsResponse & HandleError
  > {
    try {
      if (type_partner !== EnumTypePartner.DETRAN) {
        const response = await this.api.post('/portal/consult/values/debitos', {
          detran: id_partner,
          license_plate,
          renavam,
        });

        return response.data;
      } else {
        const response = await this.api.post(
          `/portal/${type_partner}/${id_partner}`,
          {
            cod_busca,
            document,
            detran: id_partner,
            license_plate,
            renavam,
          }
        );

        return response.data;
      }
    } catch (error: any) {
      return error.response.data;
    }
  }
}

export default new ParcelamosService();
