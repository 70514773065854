export type RequestCreditSale = {
  Comando: string;
  Valor: string;
  Parcelas: string;
  TipoParcela: number;
  DataTx: string;
  HoraTx: string;
  ModoParcelada: number;
  Bandeiras: [];
  Trilha2: string;
  Cvv: string;
  Cpf?: string;
};

export type RequestDebitSale = {
  Comando: string;
  Valor: string;
  DataTx: string;
  HoraTx: string;
  Cpf?: string;
};

export type ResponseCreditSale = {
  Comando: string;
  Status: StatusCreditSale;
  CodResposta: number;
  NSUTerm: number;
  ModoEntrada: ModoEntradaCreditSale;
  NSUHost: number;
  NumTerminal: string;
  NumEstab: string;
  NumAut: string;
  NumCartao: number;
  MsgHost: string;
  MsgNegada: string;
  HoraHost: number;
  DataHost: number;
  TicketLoja: string;
  TicketPortador: string;
  Adquirente: number;
  Msg?: string;
};

export enum StatusCreditSale {
  'OK' = '00',
  'Pinpad não detectado' = '01',
  'Falha conexão no Host' = '02',
  'Falha na comunicação' = '03',
  'Senha Fechamento Incorreta' = '10',
  'Num. Parcelas Inválido' = '21',
  'Valor de Parcelas Inválido' = '22',
}

enum ModoEntradaCreditSale {
  'Manual (digitado)' = '011',
  'Tarja magnética' = '021',
  'Chip EMV' = '051',
  'Chip EMV (PIN offline já validado)' = '059',
  'Fallback digitado' = '791',
  'Fallback magnético' = '801',
}

export type ResponsePinpadStatus = {
  Comando: string;
  Status: StatusPinpad;
  Versao: string;
  NSPP: string;
};

export enum StatusPinpad {
  'Serviço ativo e configurado' = '00',
  'Serviço ativo, mas não configurado' = '01',
  'Serviço ativo, mas sem conexão' = '10',
  'Outros' = '11',
}
