import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';

export const Container = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${px2vw(100)};
  border-radius: 10px;
  position: absolute;
  z-index: 9999;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: ${px2vw(80)};
  background: ${({ theme }) => theme.colors.white_full};
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.box_shadow.level_two};
`;

export const Text = styled.span<{ type: 'error' | 'info' | 'success' }>`
  font-family: 'Inter';
  font-weight: 700;
  text-align: center;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_six)};
  padding: 50px 20px;
  color: ${({ theme, type }) =>
    type === 'error'
      ? theme.colors.red_venetian
      : type === 'success'
      ? theme.colors.green_jungle
      : type === 'info'
      ? theme.colors.primary
      : theme.colors.white_full};
`;

export const Icon = styled.span`
  font-family: 'Inter';
  font-weight: 700;
  font-size: ${({ theme }) => theme.font_size.level_one};
`;
