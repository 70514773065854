import { format, parseISO } from 'date-fns';

export function clearCpfMask(document: string) {
  if (document.length === 14) {
    return document.match(/\d/g)!.join('');
  }
  return document;
}

export function formatShortToDateString(short?: string) {
  if (!short) {
    return;
  }

  const parts = short.split('/');
  if (parts.length === 3) {
    const year = parts[2];
    const day = parts[0];
    const month = parts[1];

    return format(parseISO(`${year}-${month}-${day}`), "dd 'de' MMMM yyyy");
  }
  return format(parseISO(short), "dd 'de' MMMM yyyy");
}

export function moneyMask(value: string | number) {
  const amount = Number(value);
  return (Math.round(amount * 100) / 100).toFixed(2);
}
