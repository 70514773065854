import React, { createContext, ReactNode, useEffect, useState } from 'react';

import {
  BilletResponseType,
  Debits,
  EnergyCityHallDebitsResponse,
  EnumTypePartner,
  GetPlateDebitsResponse,
  PlateDebitsDetails,
} from '../services/parcelamos/types';
import Webtef from '../services/webtef';
import { ResponseCreditSale } from '../services/webtef/types';
import useUtil from './UtilContext';

export type IdentificationType = {
  name: string;
  document: string;
  phone: string;
  payment_identifier?: string;
  payment_cart_number?: string;
};

export type PaymentInstallments = {
  installments_number: number;
  installments_value: string;
  amount: string;
};

export enum EnumPaymentForm {
  CREDIT = 1,
  DEBIT = 2,
}

export type PaymentDebitsDetails = {
  id_type_partner?: EnumTypePartner;
  id_partner?: string;
};

export type PaymentBilletType = IdentificationType &
  PaymentDebitsDetails &
  PaymentInstallments & {
    billets: BilletResponseType[];
    debits: (Debits & PlateDebitsDetails & EnergyCityHallDebitsResponse)[];
    search_data?: {
      license_plate?: string;
      renavan?: string;
    };
  };

export type PaymentOptionsRefs = {
  handleOpen: () => void;
};
interface PaymentContextData {
  setPaymentBilletData: (data: PaymentBilletType) => void;
  processPaymentBilletData: (
    data: PaymentBilletType,
    type?: EnumPaymentForm
  ) => void;
  clearPaymentBilletData: () => void;
  dataPaymentBillet: PaymentBilletType;
  paymentBilletResponse: ResponseCreditSale | undefined;
  loadingPayment: boolean;
}

const PaymentContext = createContext<PaymentContextData>(
  {} as PaymentContextData
);

export const PaymentProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [dataPaymentBillet, setDataPaymentBillet] = useState<PaymentBilletType>(
    {
      document: '',
      phone: '',
      name: '',
      amount: '',
      installments_number: 0,
      installments_value: '',
      billets: [],
      debits: [],
    }
  );

  const [paymentBilletResponse, setPaymentBilletResponse] = useState<
    ResponseCreditSale | undefined
  >();
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);

  const { showMessage } = useUtil();

  useEffect(() => {
    loadPaymentBilledData();
    function loadPaymentBilledData() {
      const data = getStorageBillet();
      if (data) {
        setDataPaymentBillet(data);
      }
    }
  }, []);

  useEffect(() => {
    console.log('testando', dataPaymentBillet);
  }, [dataPaymentBillet]);

  function getStorageBillet() {
    const data = sessionStorage.getItem('@parcelamos/payment-billet-data');
    if (data) {
      return JSON.parse(data);
    }
    return {
      document: '',
      phone: '',
      name: '',
      amount: '',
      installments_number: 0,
      installments_value: '',
      billets: [],
    };
  }

  function setStorageBillet(data: PaymentBilletType) {
    const payment_storage = getStorageBillet();

    sessionStorage.setItem(
      '@parcelamos/payment-billet-data',
      JSON.stringify({ ...payment_storage, ...data })
    );
  }

  function handleSetDataPaymentBillet(data: PaymentBilletType) {
    setStorageBillet(data);
    setDataPaymentBillet(data);
  }

  function processPaymentBillet(
    data: PaymentBilletType,
    type: EnumPaymentForm = EnumPaymentForm.CREDIT
  ) {
    if (
      !data.name &&
      !data.phone &&
      !data.document &&
      !data.installments_value &&
      data.installments_number < 0 &&
      !data.amount &&
      !data.payment_identifier
    ) {
      return showMessage({
        type: 'error',
        message: 'É necessário informar todos os dados para pagamento',
      });
    }
    setPaymentBilletResponse(undefined);
    setLoadingPayment(true);
    const webtef = new Webtef();

    if (type === EnumPaymentForm.DEBIT) {
      webtef.createDebitSale(
        {
          Valor: (Number.parseFloat(data.amount) * 100).toString(),
          Cpf: data.payment_identifier,
        },
        (_, data) => {
          setLoadingPayment(false);
          setPaymentBilletResponse(data);
        }
      );

      return;
    }

    webtef.createCreditSale(
      {
        Parcelas: data.installments_number.toString(),
        Valor: (Number.parseFloat(data.amount) * 100).toString(),
        Cpf: data.payment_identifier,
      },
      (_, data) => {
        setLoadingPayment(false);
        setPaymentBilletResponse(data);
      }
    );
  }
  function clearPaymentBilletData() {
    sessionStorage.removeItem('@parcelamos/payment-billet-data');
    setDataPaymentBillet({
      document: '',
      phone: '',
      name: '',
      amount: '',

      installments_number: 0,
      installments_value: '',
      billets: [],
      debits: [],
    });
  }

  return (
    <PaymentContext.Provider
      value={{
        setPaymentBilletData: handleSetDataPaymentBillet,
        processPaymentBilletData: processPaymentBillet,
        clearPaymentBilletData,
        dataPaymentBillet,
        paymentBilletResponse,
        loadingPayment,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

const usePayment = () => React.useContext(PaymentContext);

export default usePayment;
