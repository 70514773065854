import React, { useEffect, useState } from 'react';

import Phases from '../../components/Phases';

import {
  Container,
  Content,
  StepBack,
  TextHead,
  StepBackIcon,
  StepBackText,
  MessageContainerRead,
  ContentButtons,
  ContentCenter,
} from './styles';
import { Icons } from '../../assets/Icons';
import { ScreenTypes } from '.';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthContext';
import { EnumTypePartner, Partner } from '../../services/parcelamos/types';
import Button from '../../components/Button';
import useUtil from '../../contexts/UtilContext';
import px2vw from '../../utils/pc2vw';
import usePayment from '../../contexts/PaymentContext';

type Props = {
  goToScreen: (name: ScreenTypes) => void;
};
const AgreementsType: React.FC<Props> = ({ goToScreen }) => {
  const navigate = useNavigate();

  const [agreement, setAgreement] = useState<Partner[]>();

  const { user } = useAuth();
  const { dataPaymentBillet, setPaymentBilletData } = usePayment();
  const { showMessage } = useUtil();

  const filterTypes = (items: Partner[]) => {
    const types: string[] = [];

    items.forEach((item) => {
      if (!types.includes(item.name_type_partner)) {
        types.push(item.name_type_partner);
      }
    });
    return types;
  };
  const selectByType = (items: Partner[], key: string) =>
    items.filter((item) => item.name_type_partner === key);

  const selectAgreement = (type_name: string) => {
    if (user && user.partners) {
      return setAgreement(selectByType(user.partners, type_name));
    }
    showMessage({
      type: 'error',
      message: 'Não foi possível selecionar o tipo de convênio',
    });
  };

  const goToDebits = (id_partner: string, id_type_partner: EnumTypePartner) => {
    setPaymentBilletData({
      ...dataPaymentBillet,
      id_partner,
      id_type_partner,
    });
    goToScreen(ScreenTypes.FetchDebits);
  };
  const goBack = () => {
    if (agreement) {
      return setAgreement(undefined);
    }
    navigate('/inicial');
  };

  return (
    <Container>
      <Content>
        <Phases
          icons={[Icons.lock, Icons.pencil, Icons.lock, Icons.whiteCheck]}
          texts={['Identificação', 'Dados do pagamento', 'Pagamento', '']}
          step={2}
        />
        <ContentCenter>
          <MessageContainerRead>
            <TextHead>Selecione o tipo de convênio</TextHead>
          </MessageContainerRead>
          <ContentButtons>
            {user &&
              !agreement &&
              filterTypes(user.partners).map((name) => (
                <Button
                  style={{ minHeight: 87 }}
                  key={name}
                  variant="quartenary"
                  text={name}
                  icon="chevron_right_outline"
                  onClick={() => selectAgreement(name)}
                />
              ))}
            {user &&
              agreement &&
              agreement.map(
                ({ name, id_partner, id_type_partner, image_src }) => (
                  <Button
                    style={{ minHeight: 130 }}
                    key={name}
                    variant="quartenary"
                    text={name}
                    image={image_src}
                    icon="chevron_right_outline"
                    onClick={() => goToDebits(id_partner, id_type_partner)}
                  />
                )
              )}
          </ContentButtons>
          <StepBack onClick={goBack}>
            <StepBackIcon src={Icons.chevron_left} />
            <StepBackText>Voltar</StepBackText>
          </StepBack>
        </ContentCenter>
      </Content>
    </Container>
  );
};

export default AgreementsType;
