import React, { useRef } from 'react';
import {
  ContainerErrorPayment,
  BankslipPaymentInfo,
  ButtonsContainer,
  MainTitleInfo,
  SubTextPrimary,
  SubTextSecondary,
} from './styles';
import Button from '../../../components/Button';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { VscError } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import XCircleImage from '../../../assets/images/svg/x-circle.svg';
import UniqueOptions from '../Unique/Options';
import UniqueOptionsCredit from '../Unique/OptionsCredit';
import usePayment, {
  PaymentOptionsRefs,
} from '../../../contexts/PaymentContext';

interface ErrorPaymentProps {
  error?: string;
}

const ErrorPayment: React.FC = ({
  error = 'Houve um erro',
}: ErrorPaymentProps) => {
  const navigate = useNavigate();
  const { dataPaymentBillet, processPaymentBilletData } = usePayment();
  const unicPaymentRef = useRef<PaymentOptionsRefs>(null);
  const unicOptionsCredRef = useRef<PaymentOptionsRefs>(null);

  function openUnicPayment() {
    unicPaymentRef.current && unicPaymentRef.current.handleOpen();
  }

  function goBackOptionsCredit() {
    unicOptionsCredRef.current && unicOptionsCredRef.current.handleOpen();
    unicPaymentRef.current && unicPaymentRef.current.handleOpen();
  }

  function tryAgain() {
    processPaymentBilletData(dataPaymentBillet);
    navigate('/inserir-cartao');
  }

  function getOriginalValue() {
    const sum = dataPaymentBillet.billets.reduce(
      (value, current) => value + current.valor_boleto,
      0
    );
    return sum;
  }
  return (
    <ContainerErrorPayment>
      <BankslipPaymentInfo>
        <img src={XCircleImage} alt="Check Circle Image" />
        <MainTitleInfo>Pagamento não efetuado</MainTitleInfo>
        <SubTextPrimary>
          Não conseguimos fazer a cobrança em seu cartão.
        </SubTextPrimary>
        <SubTextSecondary>{error}</SubTextSecondary>
      </BankslipPaymentInfo>

      <ButtonsContainer>
        <Button variant="primary" onClick={tryAgain}>
          <p>Tentar Novamente</p>
          <AiOutlineArrowRight />
        </Button>
        <Button variant="primary" onClick={() => openUnicPayment()}>
          <p>Escolher meio de pagamento diferente</p>
          <AiOutlineArrowRight />
        </Button>
        <Button variant="primary" onClick={() => navigate('/inicial')}>
          <p>Cancelar</p>
          <VscError />
        </Button>
      </ButtonsContainer>
      <UniqueOptions
        value={getOriginalValue()}
        ref={unicPaymentRef}
        moreOptionsCredit={unicOptionsCredRef}
      />
      <UniqueOptionsCredit
        value={getOriginalValue()}
        ref={unicOptionsCredRef}
        goBack={goBackOptionsCredit}
      />
    </ContainerErrorPayment>
  );
};

export default ErrorPayment;
