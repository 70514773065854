import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUtil from '../../../contexts/UtilContext';
import { ContainerInfoSuccess, TitleInfo } from './styles';

export function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

const ReceiptSuccess: React.FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useUtil();
  const [count, setCount] = useState(5);

  useEffect(() => {
    showMessage({
      type: 'success',
      message: `Seu comprovante será impresso em ${count} segundos`,
      time: 5000,
    });
    const interval = setInterval(() => {
      if (count > 1) {
        return setCount(count - 1);
      }
      handleGoToImpressao();
    }, 1000);
    return () => clearInterval(interval);
  }, [count]);

  async function handleGoToImpressao() {
    navigate('/comprovante-impressao');
  }

  return (
    <ContainerInfoSuccess>
      <TitleInfo>Comprovante enviado com sucesso!</TitleInfo>
    </ContainerInfoSuccess>
  );
};

export default ReceiptSuccess;
