import styled from 'styled-components';
import px2vw from '../../../utils/pc2vw';
import pc2vw from '../../../utils/pc2vw';

export const QuestionTitle = styled.h1`
  font-weight: 200;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_one)};
  text-align: center;
  width: ${pc2vw(330)};
`;

export const ContainerEmail = styled.div`
  width: ${px2vw(768)};
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${px2vw(40)};
  padding-top: 20vh;
`;

export const KeyboardView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(20)};

  width: ${px2vw(769)};
  height: ${px2vw(367)};

  .simple-keyboard.hg-theme-default.myTheme {
    border-radius: 10px;
    width: calc(100% - 20px);

    background-color: #ffffff;
  }

  .simple-keyboard.hg-theme-white.myTheme {
    border-radius: 10px;
    margin: ${px2vw(10)};
    width: calc(100% - 20px);
    background-color: #ffffff;
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-black {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${px2vw(10)};
    gap: ${px2vw(10)};

    width: ${px2vw(58.5)};
    height: ${px2vw(76.75)};
    margin: ${px2vw(5)};
    background: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: ${px2vw(30)};
    line-height: ${px2vw(36)};
    text-align: center;

    color: #000;
  }
`;
