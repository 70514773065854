import React, { useRef, useState } from 'react';
import {
  ActionsContainer,
  BankslipContainer,
  Container,
  InformationContainer,
  InformationBody,
  InformationBodyItens,
  InformationHead,
  InformationHeadItens,
  InformationHeadText,
  InformationHeadAmount,
  InformationTextContent,
  BarcodeImage,
  InformationBoldText,
  DotDivider,
  InformationNormalText,
  MoreInformationButton,
  TrashButton,
  MoreInfoIcon,
  TrashIcon,
  InformationBodyIten,
  CheckContent,
  CheckBox,
  CheckIcon,
} from './styles';

import Barcode from '../../assets/images/svg/barcode.svg';
import ChevronDown from '../../assets/images/svg/chevron_down.svg';
import Trash from '../../assets/images/svg/trash.svg';
import Checked from '../../assets/images/svg/white-check.svg';
import { BilletType } from '../../pages/BillingData';
import DeleteBillet, {
  DeleteBilletRefs,
} from '../../pages/BillingData/deleteBillet';
import { formatShortToDateString } from '../../utils/utils';
import { bankslipMask, dinheiroMask } from 'masks-br';
import { Icons } from '../../assets/Icons';
import {
  Debits,
  EnergyCityHallDebitsResponse,
  PlateDebitsDetails,
} from '../../services/parcelamos/types';

type Props = {
  billet: Debits & PlateDebitsDetails & EnergyCityHallDebitsResponse;
  checked: boolean;
  removeBillet: () => void;
  onChangeChecked: () => void;
};

export const CheckedItem: React.FC<Props> = ({
  billet,
  checked,
  removeBillet,
  onChangeChecked,
}) => {
  const [open, setOpen] = useState<'open' | 'close' | ''>('');

  const deleteRef = useRef<DeleteBilletRefs>(null);

  function handleOpen() {
    if (open && open === 'open') {
      setOpen('close');
    } else {
      setOpen('open');
    }
  }

  const handleOpenDelete = () => {
    if (deleteRef.current) {
      deleteRef.current.handleOpen();
    }
  };

  return (
    <Container className={open}>
      <BankslipContainer>
        <CheckContent>
          <CheckBox checked={checked} onClick={onChangeChecked} />
        </CheckContent>

        <InformationContainer>
          <InformationHead>
            <BarcodeImage src={Barcode} />
            <InformationHeadItens>
              <InformationTextContent>
                <InformationHeadText>
                  {billet.descricao ? billet.descricao : 'Sem Informação'}
                </InformationHeadText>
                <InformationHeadAmount>
                  {dinheiroMask(billet.valor)}
                </InformationHeadAmount>
              </InformationTextContent>
            </InformationHeadItens>
          </InformationHead>
          <InformationBody>
            <InformationBodyItens>
              <InformationBodyIten>
                <InformationBoldText size={107}>Marca</InformationBoldText>
                <DotDivider />
                <InformationNormalText>
                  {billet.marca ? billet.marca : 'Sem Informação'} -
                  {billet.cor ? billet.cor : 'Sem Informação'}
                </InformationNormalText>
              </InformationBodyIten>
            </InformationBodyItens>

            <InformationBodyItens>
              <InformationBodyIten>
                <InformationBoldText size={107}>Cor</InformationBoldText>
                <DotDivider />
                <InformationNormalText>
                  {billet.cor ? billet.cor : 'Sem Informação'}
                </InformationNormalText>
              </InformationBodyIten>
              <InformationBodyIten>
                <InformationBoldText size={107}>Vencimento</InformationBoldText>
                <DotDivider />
                <InformationNormalText>
                  {billet.data_vencimento
                    ? formatShortToDateString(billet.data_vencimento)
                    : 'Sem Informação'}
                </InformationNormalText>
              </InformationBodyIten>
              <InformationBodyIten>
                <InformationBoldText size={128}>
                  Linha digitável
                </InformationBoldText>
                <DotDivider />
                <InformationNormalText>
                  {billet.codigo_barra
                    ? bankslipMask(billet.codigo_barra)
                    : 'Sem Informação'}
                </InformationNormalText>
              </InformationBodyIten>
            </InformationBodyItens>
          </InformationBody>
        </InformationContainer>

        <ActionsContainer>
          <MoreInformationButton onClick={() => handleOpen()}>
            <MoreInfoIcon src={ChevronDown} />
          </MoreInformationButton>
        </ActionsContainer>
      </BankslipContainer>
    </Container>
  );
};
