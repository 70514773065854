import React, { createContext, ReactNode, useEffect } from "react";
import { Loading } from "../components/Loading";

import ParcelamosService from "../services/parcelamos";
import { HandleError, User } from "../services/parcelamos/types";
import { clearCpfMask } from "../utils/utils";
import useUtil from "./UtilContext";

interface AuthContextData {
  signed: boolean;
  hasPartner: () => boolean;
  user?: User & HandleError;
  signIn({
    document,
    password,
  }: TypeLogin): Promise<(User & HandleError) | undefined>;
}

type TypeLogin = {
  document: string;
  password: string;
};
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { showMessage } = useUtil();

  const [user, setUser] = React.useState<User & HandleError>();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const user = sessionStorage.getItem("@parcelamos/sign-in");

    if (user) {
      setUser(JSON.parse(user));
    }
    setLoading(false);
  }, []);

  async function signIn({
    document,
    password,
  }: TypeLogin): Promise<(User & HandleError) | undefined> {
    const data = await ParcelamosService.login({
      document: clearCpfMask(document),
      password,
    });

    if (data.error && data.message) {
      showMessage({ type: "error", message: data.message });
      return;
    }

    sessionStorage.setItem(
      "@parcelamos/hasPartner",
      JSON.stringify({ hasPartner: data.partners.length > 0 })
    );

    sessionStorage.setItem("@parcelamos/sign-in", JSON.stringify(data));
    setUser(data);
    return data;
  }

  const hasPartner = () => {
    let hasPartner = sessionStorage.getItem("@parcelamos/hasPartner");

    if (hasPartner) {
      const data = JSON.parse(hasPartner);
      return data.hasPartner;
    }

    return false;
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <AuthContext.Provider value={{ signed: !!user, user, signIn, hasPartner }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);

export default AuthContext;
