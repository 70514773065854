import React, { useEffect, useState } from 'react';

import {
  Container,
  Content,
  LoadingMessageComponent,
  ReadyTextBody,
  ReadyTextHeader,
} from './styles';

import { Loading } from '../../components/Loading';

import { StatusPinpad } from '../../services/webtef/types';
import webtef from '../../services/webtef';
import useUtil from '../../contexts/UtilContext';
import { useNavigate } from 'react-router-dom';

const Ready: React.FC = () => {
  const { showMessage } = useUtil();
  const navigate = useNavigate();

  const [pinpadActive, setPinpadActive] = useState(false);
  const [pinpadInitialize, setPinpadInitialize] = useState(false);
  const [pinpadLoadTable, setPinpadLoadTable] = useState(false);

  useEffect(() => {
    showMessage({
      type: 'success',
      message: 'Estamos localizando o seu pinpad!',
    });
    const ws = new webtef();

    ws.getPinpadStatus((_, data) => {
      if (data.Status === StatusPinpad['Serviço ativo e configurado']) {
        setPinpadActive(true);
      }
      if (data.StatusMessage) {
        showMessage({ type: 'error', message: data.StatusMessage });
      }
    });
  }, []);

  useEffect(() => {
    if (pinpadActive) {
      showMessage({
        type: 'success',
        message: 'Estamos inicializando o seu pinpad!',
      });
      const ws = new webtef();

      ws.initialize((_, data) => {
        if (data.Status === '00') {
          setPinpadInitialize(true);
        }
        if (data.StatusMessage) {
          showMessage({ type: 'error', message: data.StatusMessage });
        }
      });
    }
  }, [pinpadActive]);

  useEffect(() => {
    if (pinpadInitialize) {
      showMessage({
        type: 'success',
        message: 'Estamos carregando as tabelas do pinpad!',
      });
      const ws = new webtef();

      ws.loadTables((_, data) => {
        if (data.Status === '00') {
          setPinpadLoadTable(true);
        }
      });
    }
  }, [pinpadInitialize]);

  useEffect(() => {
    if (pinpadLoadTable) {
      showMessage({
        type: 'success',
        message: 'Estamos abrindo o seu pinpad!',
      });
      const ws = new webtef();

      ws.opening((_, data) => {
        if (data.Status === '05') {
          showMessage({
            type: 'success',
            message: 'Pinpad pronto para o uso!',
          });
          navigate('/inicial');
        }
      });
    }
  }, [pinpadLoadTable]);

  return (
    <Container>
      <Content>
        <LoadingMessageComponent>
          <ReadyTextHeader>Preparando acesso</ReadyTextHeader>
          <ReadyTextBody>
            Estamos deixando tudo
            <br /> pronto para o totem funcionar
          </ReadyTextBody>
        </LoadingMessageComponent>
        <Loading />
      </Content>
    </Container>
  );
};

export default Ready;
