import React, { useState } from 'react';

import { Container } from './styles';

import Document from './document';
import Password from './password';
import { useAuth } from '../../contexts/AuthContext';
import useUtil from '../../contexts/UtilContext';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../theme/theme';

const Login: React.FC = () => {
  const { signIn: onLogin } = useAuth();
  const { showMessage } = useUtil();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');

  const next = () => {
    if (step < 2) {
      setStep(step + 1);
    }
  };
  const previous = () => {
    if (step >= 2) {
      setStep(step - 1);
    }
  };

  const signIn = async () => {
    if (document && password) {
      const user = await onLogin({ document, password });
      if (!user) {
        setDocument('');
        setPassword('');
        previous();
        return;
      }

      theme.logo = user.whitelabel.main_logo_url;
      theme.colors.primary = user.whitelabel.primary_color;
      theme.colors.secondary = user.whitelabel.secondary_color;
      navigate('/configurando');
      return;

      // Go to scrren logged
    }

    showMessage({
      type: 'error',
      message: 'Por favor, informar o login e a senha!',
    });
  };

  const steps = [
    {
      id: 1,
      children: (
        <Document
          key={1}
          next={next}
          setDocument={setDocument}
          document={document}
        />
      ),
    },
    {
      id: 2,
      children: (
        <Password
          key={2}
          setPassword={setPassword}
          signIn={signIn}
          password={password}
        />
      ),
    },
  ];

  return <Container>{steps.map((s) => s.id === step && s.children)}</Container>;
};

export default Login;
