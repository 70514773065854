class Enviroment {
  private _enviroment = process.env.NODE_ENV;

  get enviroment() {
    return this._enviroment;
  }

  get is_test() {
    return this._enviroment === "test";
  }

  get is_development() {
    return this._enviroment === "development";
  }

  get is_production() {
    return this._enviroment === "production";
  }
}

export default Enviroment;
