import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const KeyboardView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 70%;
  height: ${px2vw(367)};
  height: 100%;
  background-color: #ffffff;

  .simple-keyboard.hg-theme-default.myTheme {
    border-radius: 10px;
    margin: 10px;
    width: calc(100% - 20px);

    background-color: #ffffff;
  }

  .simple-keyboard.hg-theme-white.myTheme {
    border-radius: 10px;
    margin: 10px;
    width: calc(100% - 20px);
    background-color: #ffffff;
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-black {
    background-color: #ffffff;
    width: ${px2vw(140.67)};
    height: ${px2vw(76.75)};
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: ${({ theme }) => theme.colors.dark_full};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: ${px2vw(30)};
    line-height: ${px2vw(36)};
    text-align: center;
    gap: ${px2vw(10)};
    padding: ${px2vw(10)};
    margin: ${px2vw(10)};
  }

  .black & {
    background-color: #000;
    .simple-keyboard.hg-theme-default.myTheme {
      border-radius: 10px;
      margin: 10px;
      width: calc(100% - 20px);
      background-color: #000000;
    }

    .simple-keyboard.hg-layout-default .hg-button.hg-black {
      box-sizing: border-box;

      /* Auto layout */

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;

      width: 58.5px;
      height: 57.4px;

      background: rgba(255, 255, 255, 0.1);
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: ${px2vw(30)};
      line-height: ${px2vw(36)};
      text-align: center;

      color: #ffffff;
    }
  }
`;
