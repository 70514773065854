import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';
import pc2vw from '../../utils/pc2vw';

export const Container = styled.div`
  width: ${px2vw(768)};
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  width: ${px2vw(768)};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 14px;
  @media print {
    display: none;
  }
`;

export const InfoButton = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  padding: 8px 10px;
  gap: 5px;
  opacity: 0.5;
  width: ${pc2vw(150)};
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const BankslipPaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: ${px2vw(768)};
  margin: 300px 0 200px 0;
  text-align: center;
  width: ${pc2vw(330)};

  font-style: normal;
  @media print {
    display: none;
  }
`;

export const TitleInfo = styled.h1`
  font-weight: 700;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_five)};
  color: #00c04d;
`;

export const SubTextPrimary = styled.p`
  font-weight: 300;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_six)};
`;

export const SubTextSecondary = styled.span`
  font-weight: 300;
  font-size: ${({ theme }) => pc2vw(theme.font_size.level_four)};
`;
