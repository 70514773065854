import {
  EnumPaymentForm,
  PaymentBilletType,
} from '../../contexts/PaymentContext';

export type LoginRequestType = {
  document: string;
  password: string;
};
export interface PaymentCondition {
  id_condicao_pagamento: number;
  id_forma_pagamento: number;

  qtde_parcelas: number;
  coeficiente_final: number;
  comissao_padrao: number;
  taxa_conveniencia: number;
  mdr: number;
  antecipacao: number;
  prazo_padrao: number;
}

export interface IField {
  bankslip_value: number;
  payment_form: EnumPaymentForm;
  installments_number: number;
}

export interface Partner {
  id_partner: string;
  id_usuario: string;
  id_type_partner: number;
  id_estado?: number;

  name: string;
  image_src: string;
  production: boolean;

  display: boolean;
  name_type_partner: string;
  created_by: string;
  created_at: Date;
  deleted_by?: string;
  deleted_at?: Date;
}
interface PartnerField {
  id_partner_field: string;
  id_partner: string;
  id_type_field: string;

  required: boolean;
  label: string;
  input_key: string;

  created_by: string;
  created_at: Date;
  deleted_by?: string;
  deleted_at?: Date;
}

export type User = {
  usuario: {
    id_usuario: string;
    id_estabelecimento: string;
    cod_usuario?: string;
    nome: string;
    email: string;
    id_estado?: number | null;
  };
  perfis: {
    nome: string;
    id_perfil: number;
  }[];
  responsabilidades: {
    nome: string;
    id_responsabilidade: number;
  }[];
  documento_conta: {
    documento?: string | null;
    verificado?: boolean | null;
    verificado_em?: Date | null;
  };
  authenticated: boolean;
  token: string;
  permissoes: number[];
  partner_fields: PartnerField[];
  partners: Partner[];
  condicao_pagamento: PaymentCondition[];
  whitelabel: Whitelabel;
};

export default interface Whitelabel {
  id_whitelabel: string;
  id_usuario: string;

  name: string;
  primary_color: string;
  secondary_color: string;
  main_logo_url: string;
  favicon_url: string;
  client_url: string;
  domain?: string;
}

export type HandleError = {
  error?: boolean;
  message?: string;
  showMessage?: boolean;
};

export type BilletRequestType = {
  billet_code: string;
};

export type UserTotem = {
  _id?: string;
  full_name: string;
  email: string;
  cpf: string;
  created_at: Date;
  deleted_at?: Date;
  deleted_by?: string;
};

export interface BilletResponseType {
  pagador?: {
    nome: string;
    cnpj?: string;
    cpf?: string;
  };
  beneficiario?: {
    nome: string;
    cnpj?: string;
    cpf?: string;
  };
  banco_emitente?: {
    codigo: string;
    nome: string;
  };
  mensagem?: string;
  cedente: string;
  id_boleto: string;
  data_pagamento?: string; // '2020-05-30'
  data_vencimento?: string; // '2020-05-30'
  codigo_barras: string;
  linha_digitavel: string;
  valor_boleto: number;
  valor_desconto: number;
  valor_pagamento: number;
  valor_encargos: number;
  valor_outros_acrecimos: number;
  valor_outros_descontos: number;
  payed: boolean;
}

export type INotification = {
  email?: string;
  telefone?: string;
  nome?: string;
  boletos: {
    cod_boleto: string;
    liquidacao_id: string;
    valor: string;
    liquidado_em?: Date;
  }[];
  send_to_email?: boolean;
  send_to_sms?: boolean;
};

export type SavePaymentRequest = PaymentBilletType & {
  id_usuario: string;
  id_estabelecimento: string;
  search_data?: {
    plate?: string;
    renavam?: string;
  };
};

export type SavePaymentResponse = {
  id_transacao_totem: string;
  num_pedido: string;
};

export interface GetAllDebitsRequest {
  type_partner: EnumTypePartner;
  id_partner: string; // id_partner
  license_plate?: string;
  document?: string;
  renavam?: string;
  cod_busca?: string;
}

export type DebitsTotal = {
  valores: number;
  qtde: number;
};

export type Debits = {
  codigo_barra: string;
  data_vencimento: string;
  valor: number;
  descricao: string;
  is_license: boolean;
};

export interface PlateDebitsDetails {
  ano_fabricacao: number;
  ano_modelo: number;
  chassi: string;
  cor: string;
  marca: string;
  renavam: string;
  totais: DebitsTotal;
}
export type GetPlateDebitsResponse = PlateDebitsDetails & {
  debitos: Debits[];
};

export enum EnumTypePartner {
  DETRAN = 1,
  ENERGIA = 2,
  PREFEITURA = 3,
}

export type EnergyCityHallDebitsResponse = {
  codigo_barra: string;
  linha_digitavel?: string;
  identificador_terceiro?: string;
  data_vencimento?: string | Date;
  data_validade?: string | Date;
  data_infracao?: string | Date;
  valor: number;
  descricao?: string;
  valor_original?: number;
  valor_multa?: number;
  valor_juros?: number;
  valor_desconto?: number;
  situacao?: string;
  exercicio?: string;
  numero_controle?: string;
  tipo?: string;
};
