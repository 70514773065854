import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: ${px2vw(300)};
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img``;
