import React from 'react';
import { Icons } from '../../assets/Icons';
import { theme } from '../../theme/theme';

import { Container, Icon, PrimaryText, Row, SecondaryText } from './styles';

type Props = {
  primary_text: string;
  secondary_text: string;
  disabled?: boolean;
  icon: keyof typeof Icons;
  color: keyof typeof theme.colors;
  onClick?: () => void;
};

const CardInfo: React.FC<Props> = ({
  primary_text,
  secondary_text,
  disabled,
  icon,
  color,
  onClick,
}) => {
  return (
    <Container disabled={disabled} onClick={onClick}>
      <Icon src={Icons[icon]} alt="Barcode Scan Icon" />
      <Row>
        <PrimaryText>{primary_text}</PrimaryText>
        <SecondaryText>{secondary_text}</SecondaryText>
      </Row>
    </Container>
  );
};

export default CardInfo;
