import React, { useRef, useState } from 'react';
import {
  ActionsContainer,
  BankslipContainer,
  Container,
  InformationContainer,
  InformationBody,
  InformationBodyItens,
  InformationHead,
  InformationHeadItens,
  InformationHeadText,
  InformationHeadAmount,
  InformationTextContent,
  BarcodeImage,
  InformationBoldText,
  DotDivider,
  InformationNormalText,
  MoreInformationButton,
  TrashButton,
  MoreInfoIcon,
  TrashIcon,
  InformationBodyIten,
} from './styles';

import Barcode from '../../assets/images/svg/barcode.svg';
import ChevronDown from '../../assets/images/svg/chevron_down.svg';
import Trash from '../../assets/images/svg/trash.svg';
import { BilletType } from '../../pages/BillingData';
import DeleteBillet, {
  DeleteBilletRefs,
} from '../../pages/BillingData/deleteBillet';
import { formatShortToDateString } from '../../utils/utils';
import { bankslipMask, dinheiroMask } from 'masks-br';

type Props = {
  billet: BilletType;
  removeBillet: () => void;
};

export const BankslipItem: React.FC<Props> = ({ billet, removeBillet }) => {
  const [open, setOpen] = useState<'open' | 'close' | ''>('');

  const deleteRef = useRef<DeleteBilletRefs>(null);

  function handleOpen() {
    if (open && open === 'open') {
      setOpen('close');
    } else {
      setOpen('open');
    }
  }

  const handleOpenDelete = () => {
    if (deleteRef.current) {
      deleteRef.current.handleOpen();
    }
  };

  return (
    <Container className={open}>
      <BankslipContainer>
        <InformationContainer>
          <InformationHead>
            <BarcodeImage src={Barcode} />
            <InformationHeadItens>
              <InformationTextContent>
                <InformationHeadText>
                  {billet.cedente ? billet.cedente : 'Sem Informação'}
                </InformationHeadText>
                <InformationHeadAmount>
                  {dinheiroMask(billet.valor_boleto)}
                </InformationHeadAmount>
              </InformationTextContent>
            </InformationHeadItens>
          </InformationHead>
          <InformationBody>
            <InformationBodyItens>
              <InformationBodyIten>
                <InformationBoldText size={107}>Vencimento</InformationBoldText>
                <DotDivider />
                <InformationNormalText>
                  {billet.data_vencimento
                    ? formatShortToDateString(billet.data_vencimento)
                    : 'Sem Informação'}
                </InformationNormalText>
              </InformationBodyIten>
              <InformationBodyIten>
                <InformationBoldText size={128}>
                  Nosso número
                </InformationBoldText>
                <DotDivider />
                <InformationNormalText>
                  {billet.id_boleto ? billet.id_boleto : 'Sem Informação'}
                </InformationNormalText>
              </InformationBodyIten>
            </InformationBodyItens>

            <InformationBodyItens></InformationBodyItens>
            <InformationBodyItens>
              <InformationBodyIten>
                <InformationBoldText size={128}>
                  Linha digitável
                </InformationBoldText>
                <DotDivider />
                <InformationNormalText>
                  {billet.linha_digitavel
                    ? bankslipMask(billet.linha_digitavel)
                    : 'Sem Informação'}
                </InformationNormalText>
              </InformationBodyIten>
            </InformationBodyItens>

            <InformationBodyItens>
              <InformationBodyIten>
                <InformationBoldText size={95}>Instruções</InformationBoldText>
                <DotDivider />
                <InformationNormalText>
                  {billet.mensagem ? billet.mensagem : 'Sem informaçôes'}
                </InformationNormalText>
              </InformationBodyIten>
            </InformationBodyItens>
          </InformationBody>
        </InformationContainer>
        <ActionsContainer>
          <MoreInformationButton onClick={() => handleOpen()}>
            <MoreInfoIcon src={ChevronDown} />
          </MoreInformationButton>
          <TrashButton onClick={() => handleOpenDelete()}>
            <TrashIcon src={Trash} />
          </TrashButton>
        </ActionsContainer>
      </BankslipContainer>
      <DeleteBillet
        billet={billet}
        ref={deleteRef}
        removeBillet={removeBillet}
      />
    </Container>
  );
};
