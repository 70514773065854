import React from 'react';
import useUtil from '../../contexts/UtilContext';
import { Loading } from '../Loading';

import { Container } from './styles';

const LoadingScreen: React.FC = () => {
  const { loading } = useUtil();

  if (!loading) {
    return null;
  }
  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default LoadingScreen;
