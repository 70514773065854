import { createGlobalStyle } from 'styled-components';
import IntrRegular from './assets/fonts/Inter-Regular.ttf';

export const Global = createGlobalStyle`
  #root {
    height: 100%;
  }
  *, html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

  
  }
  html, body {
    height: 100%;
  }
   
  .App {
    height: 100%;
  }

  .grecaptcha-badge { 
    visibility: hidden;
}
  @font-face {
        font-family: 'Inter';
        src: local('Inter'), local('Inter'),
        url(${IntrRegular}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }
`;

export default Global;
