import React from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";

import Keyboard from "react-simple-keyboard";

import {
  Container,
  KeyboardView,
  Content,
  Text,
  Form,
  Row,
  StepBack,
  StepBackIcon,
  StepBackText,
} from "./styles";
import { layout } from "../../assets/keyboard";

import Phases from "../../components/Phases";
import { Icons } from "../../assets/Icons";
import { useNavigate } from "react-router-dom";

import { PaymentBilletType } from "../../contexts/PaymentContext";

type Props = {
  next: () => void;
  setData: (data: PaymentBilletType) => void;
  data: PaymentBilletType;
};

const Name: React.FC<Props> = ({ next, setData, data }) => {
  const navigate = useNavigate();

  function _previous() {
    setData({
      ...data,
      name: "",
    });
    navigate("/inicial");
  }

  return (
    <Container>
      <Content>
        <Phases
          icons={[Icons.lock, Icons.pencil, Icons.lock, Icons.whiteCheck]}
          texts={["Identificação", "Dados do boleto", "Pagamento", ""]}
          step={1}
        />
        <Form>
          <Text>
            Como podemos
            <br />
            te chamar?
          </Text>
          <Row>
            <Input
              placeholder="Nome e Sobrenome"
              name="client_name"
              type="name"
              value={data.name}
              onChange={(name) => setData({ ...data, name })}
              required
            />
            <Button
              text="Continuar"
              variant="primary"
              onClick={next}
              disabled={data.name.length < 3}
            />
          </Row>
          <StepBack onClick={_previous}>
            <StepBackIcon src={Icons.chevron_left} />
            <StepBackText>Voltar</StepBackText>
          </StepBack>
        </Form>
        <KeyboardView>
          <Keyboard
            onChange={(name) => setData({ ...data, name })}
            layoutName="default_two"
            layout={layout}
            theme={"hg-theme-default hg-layout-default myTheme"}
            display={{
              "{bksp}": "⌫",
              "{space}": "␣",
            }}
            buttonTheme={[
              {
                class: "hg-black",
                buttons:
                  "Q W E R T Y U I O P A S D F G H J K L Ç Z X C V B N M {bksp} {space}",
              },
            ]}
          />
        </KeyboardView>
      </Content>
    </Container>
  );
};

export default Name;
