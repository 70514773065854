import React from "react";
import { Icons } from "../../assets/Icons";
import { theme } from "../../theme/theme";

import { Container, Icon } from "./styles";

// Props
type Props = {
  text: string;
  disabled?: boolean;
  icon: keyof typeof Icons;
  color: keyof typeof theme.colors;
  onClick: () => void;
};

const ActionButton: React.FC<Props> = ({
  text,
  disabled,
  icon,
  color,
  onClick,
}) => {
  return (
    <Container
      color={theme.colors[color]}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon src={Icons[icon]} alt="Action Button Icon" />
      {text}
    </Container>
  );
};

export default ActionButton;
