import React, { useRef } from 'react';
import Button from '../../components/Button';
import Input, { InputRefs } from '../../components/Input';
import Logo from '../../components/Logo';

import {
  Container,
  Content,
  Text,
  Form,
  LogoView,
  Row,
  KeyboardView,
} from './styles';

import { cpfMask } from '../../utils/formatMasks';
import { isValidCPF } from '../../utils/validations';
import { NumericKeyboard } from '../../components/NumericKeyboard';

type Props = {
  next: () => void;
  setDocument: React.Dispatch<React.SetStateAction<string>>;
  document: string;
};

const Document: React.FC<Props> = ({ next, document, setDocument }) => {
  const inputRef = useRef<InputRefs>(null);

  const isValid = () => {
    return cpfMask(document).length === 14 && isValidCPF(cpfMask(document));
  };

  const goPassword = () => {
    if (isValid()) {
      next();
    }
  };

  return (
    <Container>
      <Content>
        <LogoView>
          <Logo />
        </LogoView>
        <Form>
          <Text>Documento de login</Text>
          <Row>
            <Input
              ref={inputRef}
              placeholder="cpf para acessar a conta"
              name="cpf"
              type="cpf"
              // value={document}
              onChange={(document) => setDocument(document)}
              required
            />
            <Button
              text="Continuar"
              variant="primary"
              onClick={goPassword}
              disabled={!isValid()}
            />
          </Row>
        </Form>
        <KeyboardView>
          <NumericKeyboard
            classNameContainer="black"
            onChange={(e) =>
              inputRef.current && inputRef.current.setValues('cpf', e)
            }
          />
        </KeyboardView>
      </Content>
    </Container>
  );
};

export default Document;
