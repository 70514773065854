import styled, { keyframes } from 'styled-components';
import px2vw from '../../utils/pc2vw';

const show = keyframes`
  0%{
    height: ${px2vw(192)};
    
  }
  100% {
    height: ${px2vw(424)};
  }

`;

const hide = keyframes`
  0%{
    height: ${px2vw(424)};
  }
  100% {
    height: ${px2vw(192)};
  }

`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const BankslipContainer = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: ${px2vw(20)} ${px2vw(30)};
  gap: ${px2vw(10)};

  width: ${px2vw(706)};
  height: ${px2vw(192)};
  overflow: hidden;

  background: ${({ theme }) => theme.colors.white_full};

  border: 2px solid rgba(33, 33, 33, 0.12);
  border-radius: ${px2vw(20)};
  .open & {
    animation: ${show} 0.5s ease-in-out both;
  }

  .close & {
    animation: ${hide} 0.5s ease-in-out both;
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(30)};

  width: ${px2vw(586)};
  height: ${px2vw(384)};
`;

export const BarcodeImage = styled.img`
  width: ${px2vw(44)};
  height: ${px2vw(44)};
`;

export const InformationHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(30)};

  width: ${px2vw(586)};
  height: ${px2vw(68)};
`;

export const InformationHeadItens = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(54)};

  width: ${px2vw(512)};
  height: ${px2vw(68)};
  left: ${px2vw(74)};
  top: 0px;
`;

export const InformationTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(10)};

  width: ${px2vw(415)};
  height: ${px2vw(68)};
`;

export const InformationHeadText = styled.text`
  width: ${px2vw(415)};
  height: ${px2vw(22)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_four)};
  line-height: ${px2vw(22)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #212121;
`;

export const InformationHeadAmount = styled.text`
  width: ${px2vw(152)};
  height: ${px2vw(36)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_two)};
  line-height: ${px2vw(36)};

  color: #212121;
`;

export const InformationBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${px2vw(30)};

  width: ${px2vw(586)};
  height: ${px2vw(286)};
`;

export const InformationBodyItens = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(10)};

  width: ${px2vw(586)};
  height: ${px2vw(54)};

  border-radius: ${px2vw(10)};
`;

export const InformationBodyIten = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: ${px2vw(10)};

  width: ${px2vw(586)};
  height: ${px2vw(22)};

  border-radius: ${px2vw(10)};
`;

export const InformationBoldText = styled.text<{ size: number }>`
  width: ${({ size }) => px2vw(size)};
  height: ${px2vw(22)};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_four)};
  line-height: ${px2vw(22)};

  color: rgba(33, 33, 33, 0.5);
`;

export const DotDivider = styled.text`
  width: ${px2vw(5)};
  height: ${px2vw(5)};

  background: rgba(33, 33, 33, 0.25);
`;

export const InformationNormalText = styled.text`
  width: ${px2vw(450)};
  height: ${px2vw(22)};
  word-break: break-all;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => px2vw(theme.font_size.level_four)};
  line-height: ${px2vw(22)};

  color: rgba(33, 33, 33, 0.5);
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 50px;

  width: ${px2vw(50)};
  height: ${px2vw(144)};
`;

export const MoreInformationButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(10)};
  gap: ${px2vw(10)};

  width: ${px2vw(50)};
  height: ${px2vw(50)};

  background: #f8f8f8;
  border-radius: ${px2vw(70)};
`;

export const TrashButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${px2vw(10)};
  gap: ${px2vw(10)};

  width: ${px2vw(50)};
  height: ${px2vw(50)};

  background: rgba(235, 87, 87, 0.11);
  border-radius: ${px2vw(100)};
  border: none;
`;

export const MoreInfoIcon = styled.img`
  width: ${px2vw(20)};
  height: ${px2vw(20)};
`;

export const TrashIcon = styled.img`
  width: ${px2vw(24)};
  height: ${px2vw(24)};
`;
