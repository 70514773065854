import styled from 'styled-components';
import px2vw from '../../utils/pc2vw';

type PropsData = { step: number; index: number };

export const ContainerOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px ${px2vw(100)};
  gap: ${px2vw(261)};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 0px;
  gap: 30px;

  width: 100%;
  height: ${px2vw(168)};
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 50px;

  width: ${px2vw(768)};
  height: ${px2vw(78)};
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;

  /* width: ${px2vw(213.33)}; */
  height: ${px2vw(78)};
`;

export const StepInternalOne = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: ${px2vw(30)};
`;

export const StepInternalTwo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px;

  width: 100%;
  height: ${px2vw(43)};
`;

export const StepContent = styled.div<PropsData>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;

  width: ${px2vw(30)};
  height: ${px2vw(30)};
  border: 1px solid #ffcd01;
  border-radius: 50px;
  background: ${({ theme }) => theme.colors.dark_outlined};

  ${({ step, index, theme }) => {
    if (step > index) {
      return {
        border: '1px solid ' + theme.colors.primary,
        background: theme.colors.primary,
      };
    }
    if (step - 1 === index) {
      return {
        border: '1px solid ' + theme.colors.primary,
        background: theme.colors.primary,
      };
    }
    if (step - 1 <= index) {
      return {
        border: 'none',
      };
    }
  }};
`;

export const Icon = styled.img`
  width: ${px2vw(18)};
  height: ${px2vw(18)};
`;

export const TimeLine = styled.div<PropsData>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: ${px2vw(183.33)};
  height: ${px2vw(3)};

  background: #ffcd01;
  ${({ step, index, theme }) => {
    if (step - 1 === index) {
      return {
        background:
          'linear-gradient(to right,#a0a09e 0%,#ffcd01 0%,#ffffff 115.34%)',
      };
    }
    if (step - 1 < index) {
      return {
        background: theme.colors.dark_outlined,
      };
    }
  }};
`;

export const StepLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DisableTextHeader = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-size: ${px2vw(15)};
  line-height: ${px2vw(25)};
  text-align: left;
  margin-top: 10px;

  color: gray;
`;

export const DisableTextContent = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-size: ${px2vw(20)};
  line-height: ${px2vw(30)};
  text-align: left;

  color: gray;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: ${px2vw(2)} solid ${({ theme }) => theme.colors.dark_outlined};
`;
